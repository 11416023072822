const synthItems = [
  {
    id: 1,
    name: 'Wordpress Lite',
    value: 'lite',
    link: '/wp-lite/synthetic/'
  },
];

const lighthouseItems = [
  // {
  //   id: 0,
  //   name: 'Adobe Classic',
  //   value: 'classic',
  //   link: '/adobe-classic/lighthouse/'
  // },
  {
    id: 1,
    name: 'Adobe Saas',
    value: 'saas',
    link: '/adobe-saas/lighthouse/'
  },
  // {
  //   id: 2,
  //   name: 'Adobe Templated',
  //   value: 'templated',
  //   link: '/adobe-templated/lighthouse/'
  // },
  {
    id: 3,
    name: 'Wordpress Lite',
    value: 'lite',
    link: '/wp-lite/lighthouse/'
  },
  {
    id: 4,
    name: 'ATH',
    value: 'ath',
    link: '/ath/lighthouse/'
  },
  {
    id: 5,
    name: 'Liberty',
    value: 'liberty',
    link: '/liberty/lighthouse/'
  },
  {
    id: 6,
    name: 'On Demand Execution',
    value: 'on-demand',
    link: '/on-demand/lighthouse/'
  }
];

const SEOHealthItems = [
  {
    id: 0,
    name: 'Adobe Saas',
    value: 'saas',
    link: '/adobe-saas/seo-health/'
  },
  {
    id: 1,
    name: 'Wordpress Lite',
    value: 'lite',
    link: '/wp-lite/seo-health/'
  },
  {
    id: 2,
    name: 'ATH',
    value: 'ath',
    link: '/ath/seo-health/'
  },
  {
    id: 3,
    name: 'Liberty',
    value: 'liberty',
    link: '/liberty/seo-health/'
  }
];

const synthSelfServiceItems = [
  {
    id: 0,
    name: 'Desktop',
    value: 'desktop',
    link: '/self-service/synthetic/desktop/'
  },
  {
    id: 1,
    name: 'Mobile',
    value: 'mobile',
    link: '/self-service/synthetic/mobile/'
  }
];

const rumTableNavItems = [
  {
    id: 0,
    name: 'All Platforms',
    value: 'all',
    link: '/all/rum-table'
  },
  {
    id: 1,
    name: 'Brand Leaderboard',
    value: 'leaderboard',
    link: '/rum-leaderboard'
  },
  // {
  //   id: 2,
  //   name: 'CXX Sites',
  //   value: 'cxx',
  //   link: '/cxx/rum-table'
  // },
  // {
  //   id: 3,
  //   name: 'Adobe Classic',
  //   value: 'classic',
  //   link: '/adobe-classic/rum-table'
  // },
  {
    id: 4,
    name: 'Adobe Saas',
    value: 'saas',
    link: '/adobe-saas/rum-table'
  },
  // {
  //   id: 5,
  //   name: 'Adobe Templated',
  //   value: 'templated',
  //   link: '/adobe-templated/rum-table'
  // },
  {
    id: 6,
    name: 'Wordpress Lite',
    value: 'lite',
    link: '/wp-lite/rum-table'
  },
  {
    id: 7,
    name: 'ATH',
    value: 'ath',
    link: '/ath/rum-table'
  },
  {
    id: 8,
    name: 'Liberty',
    value: 'liberty',
    link: '/liberty/rum-table'
  },
  // {
  //   id: 9,
  //   name: 'competitor sites',
  //   value: 'competitor',
  //   link: '/competitor/rum-table'
  // }
];

const rumKeyPagesNavItems = [
  // {
  //   id: 0,
  //   name: 'Adobe Classic',
  //   value: 'classic',
  //   link: '/adobe-classic/rum-table-key-pages'
  // },
  // {
  //   id: 1,
  //   name: 'Adobe Saas',
  //   value: 'saas',
  //   link: '/adobe-saas/rum-table-key-pages'
  // },
  // {
  //   id: 2,
  //   name: 'Adobe Templated',
  //   value: 'templated',
  //   link: '/adobe-templated/rum-table-key-pages'
  // },
  // {
  //   id: 3,
  //   name: 'Wordpress Lite',
  //   value: 'lite',
  //   link: '/wp-lite/rum-table-key-pages'
  // },
  // {
  //   id: 4,
  //   name: 'ATH',
  //   value: 'ath',
  //   link: '/ath/rum-table-key-pages'
  // },
  // {
  //   id: 5,
  //   name: 'Liberty',
  //   value: 'liberty',
  //   link: '/liberty/rum-table-key-pages'
  // },
  // {
  //   id: 6,
  //   name: 'competitor sites',
  //   value: 'competitor',
  //   link: '/competitor/rum-table-key-pages'
  // }
];

const rumChartNavItems = [
  // {
  //   id: 0,
  //   name: 'Adobe Classic',
  //   value: 'classic',
  //   link: '/adobe-classic/rum'
  // },
  {
    id: 1,
    name: 'Adobe Saas',
    value: 'saas',
    link: '/adobe-saas/rum'
  },
  // {
  //   id: 2,
  //   name: 'Adobe Templated',
  //   value: 'templated',
  //   link: '/adobe-templated/rum'
  // },
  {
    id: 3,
    name: 'Wordpress Lite',
    value: 'lite',
    link: '/wp-lite/rum'
  },
  {
    id: 4,
    name: 'ATH',
    value: 'ath',
    link: '/ath/rum'
  },
  {
    id: 5,
    name: 'Liberty',
    value: 'liberty',
    link: '/liberty/rum'
  },
  // {
  //   id: 6,
  //   name: 'competitor sites',
  //   value: 'competitor',
  //   link: '/competitor/rum'
  // }
];

const sitesNavItems = [
  // {
  //   id: 0,
  //   name: 'Adobe Classic',
  //   value: 'classic',
  //   link: '/adobe-classic/data'
  // },
  {
    id: 1,
    name: 'Adobe Saas',
    value: 'saas',
    link: '/adobe-saas/data'
  },
  // {
  //   id: 2,
  //   name: 'Adobe Templated',
  //   value: 'templated',
  //   link: '/adobe-templated/data'
  // },
  {
    id: 3,
    name: 'Wordpress Lite',
    value: 'lite',
    link: '/wp-lite/data'
  },
  {
    id: 4,
    name: 'ATH',
    value: 'ath',
    link: '/ath/data'
  },
  {
    id: 5,
    name: 'Liberty',
    value: 'liberty',
    link: '/liberty/data'
  },
  // {
  //   id: 6,
  //   name: 'competitor sites',
  //   value: 'competitor',
  //   link: '/competitor/data'
  // }
];

export {
  synthItems,
  synthSelfServiceItems,
  rumTableNavItems,
  sitesNavItems,
  rumChartNavItems,
  rumKeyPagesNavItems,
  lighthouseItems,
  SEOHealthItems
};
