import ActionCreators from './creators';
const API_KEY = process.env.REACT_APP_DASHBOARD_API_KEY_RUM;
const GLOBAL_API_KEY = process.env.REACT_APP_DASHBOARD_GLOBAL_API_KEY;
const DASHIE_API_KEY = process.env.REACT_APP_DASHIE_API_KEY;
const SYNTHETIC_API_KEY = process.env.REACT_APP_DASHBOARD_API_KEY_SYNTHETIC;
const API_ENDPOINT = process.env.REACT_APP_DASHBOARD_API_RUM;
const GLOBAL_API_ENDPOINT = process.env.REACT_APP_DASHBOARD_GLOBAL_API;
const DASHIE_API_ENDPOINT = process.env.REACT_APP_DASHIE_API;
const SYNTHETIC_API_ENDPOINT = process.env.REACT_APP_DASHBOARD_SYNTHETIC_API;
const SYNTHETIC_FOR_PLATFORM_API =
  process.env.REACT_APP_SYNTHETIC_FOR_PLATFORM_API;
const SYNTHETIC_FOR_PLATFORM_API_KEY =
  process.env.REACT_APP_SYNTHETIC_FOR_PLATFORM_API_KEY;
const LIGHTHOUSE_FOR_PLATFORM_API =
  process.env.REACT_APP_LIGHTHOUSE_FOR_PLATFORM_API;
const LIGHTHOUSE_FOR_PLATFORM_API_KEY =
  process.env.REACT_APP_LIGHTHOUSE_FOR_PLATFORM_API_KEY;
const GSC_SEO_FOR_PLATFORM_API =
  process.env.REACT_APP_GSC_SEO_FOR_PLATFORM_API;
const GSC_SEO_FOR_PLATFORM_API_KEY =
  process.env.REACT_APP_LIGHTHOUSE_FOR_PLATFORM_API_KEY;;
const RUM_DATA_SERVICE = `${API_ENDPOINT}/rumData/`;

const fetchOptions = {
  headers: { 'X-Api-Key': API_KEY },
  method: 'GET',
  mode: 'cors'
};
const fetchOptionsSynthetic = {
  headers: { 'X-Api-Key': SYNTHETIC_API_KEY },
  method: 'GET',
  mode: 'cors'
};

const fetchOptionsSyntheticForPlatfom = {
  headers: { 'X-Api-Key': SYNTHETIC_FOR_PLATFORM_API_KEY },
  method: 'GET',
  mode: 'cors'
};

const fetchOptionsSeoHealthSummaryForPlatform = {
  headers: { 'X-Api-Key': GSC_SEO_FOR_PLATFORM_API_KEY },
  method: 'GET',
  mode: 'cors'
};

const fetchOptionsLighthouseForPlatform = {
  headers: { 'X-Api-Key': LIGHTHOUSE_FOR_PLATFORM_API_KEY },
  method: 'GET',
  mode: 'cors'
};

const executeOnDemandLighthouse = {
  headers: { 'X-Api-Key': LIGHTHOUSE_FOR_PLATFORM_API_KEY },
  method: 'POST',
  mode: 'cors'
};

const fetchOnDemandLighthouseReport = {
  headers: { 'X-Api-Key': LIGHTHOUSE_FOR_PLATFORM_API_KEY },
  method: 'GET',
  mode: 'cors'
};

const fetchOptionsGlobal = {
  headers: { 'X-Api-Key': GLOBAL_API_KEY },
  method: 'GET',
  mode: 'cors'
};

const fetchOptionsDashie = {
  headers: { 'X-Api-Key': DASHIE_API_KEY },
  method: 'GET',
  mode: 'cors'
};

const Actions = {
  fetchAllSite() {
    return async dispatch => {
      dispatch(ActionCreators.requestAllSites());
      const URL = API_ENDPOINT.concat(`/rum/average/${30}/all`);
      const data = await fetch(URL, fetchOptions);
      const value = await data.json();
      dispatch(ActionCreators.receiveAllSites(value));
    };
  },
  fetchCurrent(dataName, days) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestCurrent(dataName));
        const URL = API_ENDPOINT.concat(
          `/rum/average/${days}/${encodeURIComponent(dataName)}`
        );
        const data = await fetch(URL, fetchOptions);
        const value = await data.json();
        dispatch(ActionCreators.receiveCurrent(value));
      } catch (error) {
        dispatch(ActionCreators.failureCurrent(error));
      }
    };
  },

  fetchCardCharts(type, dataName) {
    return async dispatch => {
      switch (type) {
        case 'categories': {
          dispatch(ActionCreators.requestChartsCategories(dataName));
          const URL = API_ENDPOINT.concat(`/rum/average/${30}/${dataName}`);
          const data = await fetch(URL, fetchOptions);
          const value = await data.json();
          dispatch(ActionCreators.receiveChartsCategories(dataName, value));
          break;
        }
        case 'subCategories': {
          dispatch(ActionCreators.requestChartsSubCategories(dataName));
          const URL = API_ENDPOINT.concat(`/rum/average/${30}/${dataName}`);
          const data = await fetch(URL, fetchOptions);
          const value = await data.json();
          dispatch(ActionCreators.receiveChartsSubCategories(dataName, value));
          break;
        }
        case 'brands': {
          dispatch(ActionCreators.requestChartsBrands(dataName));
          const URL = API_ENDPOINT.concat(`/rum/average/${30}/${dataName}`);
          const data = await fetch(URL, fetchOptions);
          const value = await data.json();
          dispatch(ActionCreators.receiveChartsBrands(dataName, value));
          break;
        }
        case 'agencies': {
          dispatch(ActionCreators.requestChartsAgencies(dataName));
          const URL = API_ENDPOINT.concat(`/rum/average/${30}/${dataName}`);
          const data = await fetch(URL, fetchOptions);
          const value = await data.json();
          dispatch(ActionCreators.receiveChartsAgencies(dataName, value));
          break;
        }
        case 'clusters': {
          dispatch(ActionCreators.requestChartsClusters(dataName));
          const URL = API_ENDPOINT.concat(`/rum/average/${30}/${dataName}`);
          const data = await fetch(URL, fetchOptions);
          const value = await data.json();
          dispatch(ActionCreators.receiveChartsClusters(dataName, value));
          break;
        }
        case 'MCOs': {
          dispatch(ActionCreators.requestChartsMCOs(dataName));
          const URL = API_ENDPOINT.concat(`/rum/average/${30}/${dataName}`);
          const data = await fetch(URL, fetchOptions);
          const value = await data.json();
          dispatch(ActionCreators.receiveChartsMCOs(dataName, value));
          break;
        }
        case 'countries': {
          dispatch(ActionCreators.requestChartsCountries(dataName));
          const URL = API_ENDPOINT.concat(`/rum/average/${30}/${dataName}`);
          const data = await fetch(URL, fetchOptions);
          const value = await data.json();
          dispatch(ActionCreators.receiveChartsCountries(dataName, value));
          break;
        }
        default:
          throw Error(
            `Expected one of cluster, mcos, countries, categories, subCategories, agencies or brands; received ${type}`
          );
      }
    };
  },

  fetchApps(brandName) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestApps());
        const URL = API_ENDPOINT.concat(`/apps/${brandName}`);
        const data = await fetch(URL, fetchOptions);
        const value = await data.json();
        let apps = {};
        value.map(v => {
          apps[v.AppId] = Object.assign({}, v, {
            loading: false,
            error: '',
            data: []
          });
          return v;
        });
        dispatch(ActionCreators.receiveApps(value, apps));
      } catch (err) {
        dispatch(ActionCreators.failureApps(err));
      }
    };
  },

  fetchChart(appId) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestChart(appId));
        const URL = API_ENDPOINT.concat(`/rum/${appId}`);
        const data = await fetch(URL, fetchOptions);
        const value = await data.json();
        dispatch(ActionCreators.receiveChart(appId, value));
      } catch (err) {
        dispatch(ActionCreators.failureApps(appId, err));
      }
    };
  },

  fetchCategories() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestRumCategories());
        const URL = API_ENDPOINT.concat('/categories');
        const data = await fetch(URL, fetchOptions);
        let value = await data.json();
        let charts = {};
        value = value.filter((obj, pos, arr) => {
          return arr.indexOf(obj) === pos;
        });
        value.map(val => {
          charts[val.Name] = Object.assign(
            {},
            { loading: false, data: [] },
            val
          );
          return val;
        });
        dispatch(ActionCreators.receiveRumCategories(value, charts));
      } catch (err) {
        dispatch(ActionCreators.failureRumCategories(err));
      }
    };
  },

  fetchSubCategories(category) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestRumSubCategories());
        const URL = API_ENDPOINT.concat(`/subCategories/${category}`);
        const data = await fetch(URL, fetchOptions);
        let value = await data.json();
        let charts = {};
        value.map(val => {
          charts[val.Name] = Object.assign(
            {},
            { loading: false, data: [] },
            val
          );
          return val;
        });
        dispatch(ActionCreators.receiveRumSubCategories(value, charts));
      } catch (err) {
        dispatch(ActionCreators.failureRumSubCategories(err));
      }
    };
  },

  fetchBrands(type, typeName) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestRumBrands());
        let URL = API_ENDPOINT;
        switch (type) {
          case 'Country':
            URL = URL.concat(`/brands/country/${typeName}`);
            break;
          case 'Agency':
            URL = URL.concat(`/brands/agency/${typeName}`);
            break;
          case 'SubCategory':
            URL = URL.concat(`/brands/subcategory/${typeName}`);
            break;
          default:
            throw Error(
              `Wrong type expected one of Country, Agency or SubCategory but got ${type}`
            );
        }
        const data = await fetch(URL, fetchOptions);
        let value = await data.json();
        let charts = {};
        value = value.filter((obj, pos, arr) => {
          return arr.indexOf(obj) === pos;
        });
        value.map(val => {
          charts[val.Name] = Object.assign(
            {},
            { loading: false, data: [] },
            val
          );
          return val;
        });
        dispatch(ActionCreators.receiveRumBrands(value, charts));
      } catch (err) {
        dispatch(ActionCreators.failureRumBrands(err));
      }
    };
  },

  fetchClusters() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestRumClusters());
        const URL = API_ENDPOINT.concat('/clusters');
        const data = await fetch(URL, fetchOptions);
        let value = await data.json();
        let charts = {};
        value = value.filter((obj, pos, arr) => {
          return arr.indexOf(obj) === pos;
        });
        value.map(val => {
          charts[val.Name] = Object.assign(
            {},
            { loading: false, data: [] },
            val
          );
          return val;
        });
        dispatch(ActionCreators.receiveRumClusters(value, charts));
      } catch (err) {
        dispatch(ActionCreators.failureRumClusters(err));
      }
    };
  },

  fetchMCOs(cluster) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestRumMCOs());
        const URL = API_ENDPOINT.concat(`/mcos/${cluster}`);
        const data = await fetch(URL, fetchOptions);
        let value = await data.json();
        let charts = {};
        value = value.filter((obj, pos, arr) => {
          return arr.indexOf(obj) === pos;
        });
        value.map(val => {
          charts[val.Name] = Object.assign(
            {},
            { loading: false, data: [] },
            val
          );
          return val;
        });
        dispatch(ActionCreators.receiveRumMCOs(value, charts));
      } catch (err) {
        dispatch(ActionCreators.failureRumMCOs(err));
      }
    };
  },

  fetchCountries(MCO) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestRumCountries());
        const URL = API_ENDPOINT.concat(`/countries/${MCO}`);
        const data = await fetch(URL, fetchOptions);
        let value = await data.json();
        let charts = {};
        value = value.filter((obj, pos, arr) => {
          return arr.indexOf(obj) === pos;
        });
        value.map(val => {
          charts[val.Name] = Object.assign(
            {},
            { loading: false, data: [] },
            val
          );
          return val;
        });
        dispatch(ActionCreators.receiveRumCountries(value, charts));
      } catch (err) {
        dispatch(ActionCreators.failureRumCountries(err));
      }
    };
  },

  fetchAgencies() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestRumAgencies());
        const URL = API_ENDPOINT.concat(`/agencies`);
        const data = await fetch(URL, fetchOptions);
        let value = await data.json();
        let charts = {};
        value = value.filter((obj, pos, arr) => {
          return arr.indexOf(obj) === pos;
        });
        value.map(val => {
          charts[val.Name] = Object.assign(
            {},
            { loading: false, data: [] },
            val
          );
          return val;
        });
        dispatch(ActionCreators.receiveRumAgencies(value, charts));
      } catch (err) {
        dispatch(ActionCreators.failureRumAgencies(err));
      }
    };
  },

  fetchBrandMetadata() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestBrandMetadata());
        const URL = API_ENDPOINT.concat(`/brandsMeta`);
        const data = await fetch(URL, fetchOptions);
        let value = await data.json();
        value = value.filter((obj, pos, arr) => {
          return arr.indexOf(obj) === pos;
        });
        dispatch(ActionCreators.receiveBrandMetadata(value));
      } catch (err) {
        dispatch(ActionCreators.failureBrandMetadata(err));
      }
    };
  },

  fetchAppInstanceMetadata() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestAppInstanceMetadata());
        const URL = GLOBAL_API_ENDPOINT.concat(`/technicalDashboardData`);
        const data = await fetch(URL, fetchOptionsGlobal);
        let value = await data.json();
        dispatch(ActionCreators.receiveAppInstanceMetadata(value));
      } catch (err) {
        dispatch(ActionCreators.failureAppInstanceMetadata(err));
      }
    };
  },

  fetchBrandAdoptionData() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestBrandAdoptionData());
        const URL = DASHIE_API_ENDPOINT.concat(`/getBrandAdoptionData`);
        const data = await fetch(URL, fetchOptionsDashie);
        let value = await data.json();
        dispatch(ActionCreators.receiveBrandAdoptionData(value));
      } catch (err) {
        dispatch(ActionCreators.failureBrandAdoptionData(err));
      }
    };
  },

  fetchAppMetadata() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestAppMetadata());
        const URL = API_ENDPOINT.concat(`/appMetadata`);
        const data = await fetch(URL, fetchOptions);
        let value = await data.json();
        dispatch(ActionCreators.receiveAppMetadata(value));
      } catch (err) {
        dispatch(ActionCreators.failureAppMetadata(err));
      }
    };
  },

  fetchDailyReport(startDate, endDate) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestDailyReport());
        const URL = API_ENDPOINT.concat(`/rum/range/${startDate}/${endDate}`);
        const data = await fetch(URL, fetchOptions);
        let value = await data.json();
        dispatch(ActionCreators.receiveDailyReport(value));
      } catch (err) {
        dispatch(ActionCreators.failureDailyReport(err));
      }
    };
  },

  fetchRumLeaderboard() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestRumLeaderboard());
        const URL = API_ENDPOINT.concat(`/rumLeaderboard`);
        const data = await fetch(URL, fetchOptions);
        let value = await data.json();
        dispatch(ActionCreators.receiveRumLeaderboard(value));
      } catch (err) {
        dispatch(ActionCreators.failureRumLeaderboard(err));
      }
    };
  },

  fetchAppMetadataKeyPages() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestAppMetadataKeyPages());
        const URL = API_ENDPOINT.concat(`/appMetadataKeyPages`);
        const data = await fetch(URL, fetchOptions);
        let value = await data.json();
        dispatch(ActionCreators.receiveAppMetadataKeyPages(value));
      } catch (err) {
        dispatch(ActionCreators.failureAppMetadataKeyPages(err));
      }
    };
  },

  fetchAppMetadataPerceivedLoad() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestAppMetadataPerceivedLoad());
        const URL = API_ENDPOINT.concat(`/appMetadataPerceivedLoad`);
        const data = await fetch(URL, fetchOptions);
        let value = await data.json();
        dispatch(ActionCreators.receiveAppMetadataPerceivedLoad(value));
      } catch (err) {
        dispatch(ActionCreators.failureAppMetadataPerceivedLoad(err));
      }
    };
  },

  setBrand: ActionCreators.setBrand,
  clearBrand: ActionCreators.clearBrand,

  initLogin: ActionCreators.initLogin,
  setLogin: ActionCreators.setLogin,
  errorLogin: ActionCreators.errorLogin,
  clearLogin: ActionCreators.clearLogin,


  fetchBrandRumDataPageLoad(brandName) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestBrandRumDataPageLoad());

        const fetchOptions = { headers: { 'x-api-key': API_KEY } };
        const URL = RUM_DATA_SERVICE.concat(`${brandName}`);
        const rawData = await fetch(URL, fetchOptions);
        const data = await rawData.json();
        dispatch(ActionCreators.receiveBrandRumDataPageLoad(data, brandName));
      } catch (error) {
        dispatch(
          ActionCreators.failureBrandRumDataPageLoad(
            `Error getting New Relic Brand Page Load data ${error.message}`
          )
        );
      }
    };
  },

  fetchCompetitorKeyPagesData() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestCompetitorKeyPagesData());
        const URL = SYNTHETIC_API_ENDPOINT.concat('/competitorDataKeyPages');
        const rawData = await fetch(URL, fetchOptionsSynthetic);
        const data = await rawData.json();
        dispatch(ActionCreators.receiveCompetitorKeyPagesData(data));
      } catch (error) {
        dispatch(ActionCreators.failureCompetitorKeyPagesData(error));
      }
    };
  },

  fetchCompetitorKeyPagesSummary() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestCompetitorKeyPagesSummary());
        const URL = SYNTHETIC_API_ENDPOINT.concat(
          '/competitorDataKeyPagesSummary'
        );
        const rawData = await fetch(URL, fetchOptionsSynthetic);
        const data = await rawData.json();
        dispatch(ActionCreators.receiveCompetitorKeyPagesSummary(data));
      } catch (error) {
        dispatch(ActionCreators.failureCompetitorKeyPagesSummary(error));
      }
    };
  },

  fetchSyntheticKeyPagesData() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestSynethicKeyPagesData());
        const URL = SYNTHETIC_API_ENDPOINT.concat('/syntheticDataKeyPages');
        const rawData = await fetch(URL, fetchOptionsSynthetic);
        const data = await rawData.json();
        dispatch(ActionCreators.receiveSynethicKeyPagesData(data));
      } catch (error) {
        dispatch(ActionCreators.failureSynethicKeyPagesData(error.message));
      }
    };
  },

  fetchSyntheticKeyPagesDataForPlatform(platform) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestSyntheticKeyPagesForPlatformData());
        const URL = `${SYNTHETIC_FOR_PLATFORM_API}/summaryData/${platform}`;
        const rawData = await fetch(URL, fetchOptionsSyntheticForPlatfom);
        const data = await rawData.json();
        dispatch(
          ActionCreators.receiveSyntheticKeyPagesForPlatformData(data, platform)
        );
      } catch (error) {
        dispatch(
          ActionCreators.failureSyntheticKeyPagesForPlatformData(error.message)
        );
      }
    };
  },

  fetchSEOHealthSummaryDataForPlatform(platform) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestGscSEOHealthSummaryForPlatformData());
        const url = `${GSC_SEO_FOR_PLATFORM_API}/sitemapSummary/${platform}`;
        const fetchData = await fetch(url, fetchOptionsSeoHealthSummaryForPlatform);
        const value = await fetchData.json();
        const { items, summary } = value;
        dispatch(
          ActionCreators.receiveGscSEOHealthSummaryForPlatformData(
            items,
            summary,
            platform
          )
        );
      } catch (error) {
        dispatch(
          ActionCreators.failureGscSEOHealthSummaryForPlatformData(error.message)
        );
      }
    };
  },

  fetchSEOHealthSitemapDataForPlatform(platform, domain) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestGscSEOHealthSitemapForPlatformData());
        const url = `${GSC_SEO_FOR_PLATFORM_API}/sitemap/${platform}/${domain}`;
        const rawData = await fetch(url, fetchOptionsSeoHealthSummaryForPlatform);
        const value = await rawData.json();
        dispatch(
          ActionCreators.receiveGscSEOHealthSitemapForPlatformData(value.data)
        );
      } catch (error) {
        dispatch(
          ActionCreators.failureGscSEOHealthSitemapForPlatformData(error.message)
        );
      }
    };
  },

  fetchSEOHealthInspectionDataForPlatform(platform, domain) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestGscSEOHealthInspectionForPlatformData());
        const url = `${GSC_SEO_FOR_PLATFORM_API}/inspection/${platform}/${domain}`;
        const rawData = await fetch(url, fetchOptionsSeoHealthSummaryForPlatform);
        const value = await rawData.json();
        dispatch(
          ActionCreators.receiveGscSEOHealthInspectionForPlatformData(value.data)
        );
      } catch (error) {
        dispatch(
          ActionCreators.failureGscSEOHealthInspectionForPlatformData(error.message)
        );
      }
    };
  },

  fetchLighthouseKeyPagesDataForPlatform(platform, date, metric) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestLighthouseKeyPagesForPlatformData());
        const URL3g = `${LIGHTHOUSE_FOR_PLATFORM_API}/ItemsData/${date}/${platform}/3g/${metric}`;
        const URLcable = `${LIGHTHOUSE_FOR_PLATFORM_API}/ItemsData/${date}/${platform}/cable/${metric}`;
        const dataArray = await Promise.all([
          fetch(URL3g, fetchOptionsLighthouseForPlatform),
          fetch(URLcable, fetchOptionsLighthouseForPlatform)
        ]);
        const data3g = await dataArray[0].json();
        const dataCable = await dataArray[1].json();
        let data = [data3g, dataCable];
        dispatch(
          ActionCreators.receiveLighthouseKeyPagesForPlatformData(
            data,
            platform
          )
        );
      } catch (error) {
        dispatch(
          ActionCreators.failureLighthouseKeyPagesForPlatformData(error.message)
        );
      }
    };
  },

  fetchLighthouseSummaryDataForPlatform(platform, date, metric) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestLighthouseSummaryForPlatformData());
        const URL3g = `${LIGHTHOUSE_FOR_PLATFORM_API}/SummaryData/${date}/${platform}/3g/${metric}`;
        const URLcable = `${LIGHTHOUSE_FOR_PLATFORM_API}/SummaryData/${date}/${platform}/cable/${metric}`;
        const dataArray = await Promise.all([
          fetch(URL3g, fetchOptionsLighthouseForPlatform),
          fetch(URLcable, fetchOptionsLighthouseForPlatform)
        ]);
        const data3g = await dataArray[0].json();
        const dataCable = await dataArray[1].json();

        let data = [data3g, dataCable];
        dispatch(
          ActionCreators.receiveLighthouseSummaryForPlatformData(data, platform)
        );
      } catch (error) {
        dispatch(
          ActionCreators.failureLighthouseSummaryForPlatformData(error.message)
        );
      }
    };
  },

  fetchLighthouseSummaryDataForBrandAndLocale(brand, locale, platform, mode) {
    return async dispatch => {
      try {
        dispatch(
          ActionCreators.requestLighthouseSummaryDataForBrandAndLocale()
        );
        const URL = `${LIGHTHOUSE_FOR_PLATFORM_API}/DomainSummaryData/${platform}/${brand}/${locale}/${mode}`;
        const rawData = await fetch(URL, fetchOptionsLighthouseForPlatform);
        const data = await rawData.json();
        dispatch(
          ActionCreators.receiveLighthouseSummaryDataForBrandAndLocale(data)
        );
      } catch (error) {
        dispatch(
          ActionCreators.failureLighthouseSummaryDataForBrandAndLocale(
            error.message
          )
        );
      }
    };
  },

  executeOnDemandLighthouseReport(body) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestExecuteOnDemandLighthouseReport());
        const URL = `${LIGHTHOUSE_FOR_PLATFORM_API}/onDemand`;
        const rawData = await fetch(URL, {
          ...executeOnDemandLighthouse,
          body: JSON.stringify(body)
        });
        const data = await rawData.json();
        dispatch(ActionCreators.receiveExecuteOnDemandLighthouseReport(data));
      } catch (error) {
        ActionCreators.failureExecuteOnDemandLighthouseReport(error.message);
      }
    };
  },

  fetchOnDemandLighthouseReport(userId) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestOnDemandLighthouseReport());
        const URL = `${LIGHTHOUSE_FOR_PLATFORM_API}/onDemandReport/${userId}`;
        const rawData = await fetch(URL, fetchOnDemandLighthouseReport);
        const data = await rawData.json();
        dispatch(ActionCreators.receiveOnDemandLighthouseReport(data));
      } catch (error) {
        dispatch(ActionCreators.failureOnDemandLighthouseReport(error.message));
      }
    };
  },

  fetchOnDemandRecentReport() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestOnDemandRecentReport());
        const URL = `${LIGHTHOUSE_FOR_PLATFORM_API}/getRecentReport`;
        const rawData = await fetch(URL, fetchOnDemandLighthouseReport);
        const data = await rawData.json();
        dispatch(ActionCreators.receiveOnDemandRecentReport(data));
      } catch (error) {
        dispatch(ActionCreators.failureOnDemandRecentReport(error.message));
      }
    };
  },

  fetchOnDemandEmailReport(email) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestOnDemandEmailReport());
        const URL = `${LIGHTHOUSE_FOR_PLATFORM_API}/getReportIds/${email}`;
        const rawData = await fetch(URL, fetchOnDemandLighthouseReport);
        const data = await rawData.json();
        dispatch(ActionCreators.receiveOnDemandEmailReport(data));
      } catch (error) {
        dispatch(ActionCreators.failureOnDemandEmailReport(error.message));
      }
    };
  },

  fetchSyntheticMonthSummary(platform) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestSyntheticMonthSummaryData);
        const URL = `${SYNTHETIC_FOR_PLATFORM_API}/summaryMonth`;
        const rawData = await fetch(URL, fetchOptionsSyntheticForPlatfom);
        const data = await rawData.json();
        data.platform = platform;
        dispatch(ActionCreators.receiveSyntheticMonthSummaryData(data));
      } catch (error) {
        dispatch(
          ActionCreators.failureSyntheticMonthSummaryData(error.message)
        );
      }
    };
  },
  fetchSyntheticRawDataForUrlAndPlatform(platform, url) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestSyntheticRawDataForUrlAndPlatform());
        const URL = `${SYNTHETIC_FOR_PLATFORM_API}/rawData/${platform}/${url}`;
        const rawData = await fetch(URL, fetchOptionsSyntheticForPlatfom);
        const data = await rawData.json();
        dispatch(ActionCreators.receiveSyntheticRawDataForUrlAndPlatform(data));
      } catch (error) {
        dispatch(
          ActionCreators.failureSyntheticRawDataForUrlAndPlatform(error.message)
        );
      }
    };
  },

  fetchLighthouseRawDataForUrlAndPlatform(platform, url, mode) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestLighthouseRawDataForUrlAndPlatform());
        const URL = `${LIGHTHOUSE_FOR_PLATFORM_API}/RawData/${platform}/${url}/${mode}`;
        const rawData = await fetch(URL, fetchOptionsLighthouseForPlatform);
        const data = await rawData.json();
        dispatch(
          ActionCreators.receiveLighthouseRawDataForUrlAndPlatform(data)
        );
      } catch (error) {
        dispatch(
          ActionCreators.failureLighthouseRawDataForUrlAndPlatform(
            error.message
          )
        );
      }
    };
  },

  fetchSyntheticKeyPagesSummary() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestSynethicKeyPagesSummary());
        const URL = SYNTHETIC_API_ENDPOINT.concat(
          '/syntheticDataKeyPagesSummary'
        );
        const rawData = await fetch(URL, fetchOptionsSynthetic);
        const data = await rawData.json();
        dispatch(ActionCreators.receiveSynethicKeyPagesSummary(data));
      } catch (error) {
        dispatch(ActionCreators.failureSynethicKeyPagesSummary(error.message));
      }
    };
  },
  fetchMetrics() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestMetrics);
        const URL = API_ENDPOINT.concat('/rum/average/30/all');
        const data = await fetch(URL, fetchOptions);
        const value = await data.json();
        delete value.items;
        dispatch(ActionCreators.receiveMetrics(value));
      } catch (error) {
        dispatch(ActionCreators.failureMetrics(error));
      }
    };
  },
  fetchSelfServiceData(brand, locale) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestSyntheticSelfService);

        const URL = SYNTHETIC_FOR_PLATFORM_API.concat(
          `/selfService/${brand}/${locale}`
        );

        const data = await fetch(URL, fetchOptionsSyntheticForPlatfom);
        const value = await data.json();
        delete value.items;
        dispatch(ActionCreators.receiveSyntheticSelfService(value));
      } catch (error) {
        dispatch(ActionCreators.failureSyntheticSelfService(error));
      }
    };
  },
  fetchSelfServiceSummaryData() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestSyntheticSelfService);
        const URL = SYNTHETIC_FOR_PLATFORM_API.concat('/selfServiceSummary');
        const data = await fetch(URL, fetchOptionsSyntheticForPlatfom);
        const value = await data.json();
        delete value.items;
        dispatch(ActionCreators.receiveSyntheticSelfServiceSummary(value));
      } catch (error) {
        dispatch(ActionCreators.failureSyntheticSelfServiceSummary(error));
      }
    };
  },
  fetchSelfServiceMobileData() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestSyntheticSelfService);

        const URL = SYNTHETIC_FOR_PLATFORM_API.concat('/selfServiceMobile');

        const data = await fetch(URL, fetchOptionsSyntheticForPlatfom);
        const value = await data.json();
        delete value.items;
        dispatch(ActionCreators.receiveSyntheticSelfService(value));
      } catch (error) {
        dispatch(ActionCreators.failureSyntheticSelfService(error));
      }
    };
  },
  fetchAllMetrics() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestAllMetrics);
        const URL = DASHIE_API_ENDPOINT.concat('/getMetrics');
        const data = await fetch(URL, fetchOptionsDashie);
        const value = await data.json();
        dispatch(ActionCreators.receiveAllMetrics(value));
      } catch (error) {
        dispatch(ActionCreators.failureAllMetrics(error));
      }
    };
  },
  fetchCxxSiteData() {
    return async dispatch => {
      try {
        const URL = DASHIE_API_ENDPOINT.concat('/cxxRumData');
        dispatch(ActionCreators.requestCxxSiteData);

        const response = await fetch(URL, fetchOptionsDashie);
        const { data, summaryData } = await response.json();
        dispatch(ActionCreators.receiveCxxSiteData(data, summaryData));
      } catch (error) {
        dispatch(ActionCreators.failureCxxSiteData(error));
      }
    };
  }
};

export default Actions;
