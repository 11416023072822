import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
// import AdobeClassicRouter from './AdobeClassicRouter';
import WpLiteRouter from './WpLiteRouter';
import Home from '../../containers/Home';
import NoMatch from '../MessageComponents/NoMatch';
// import AdobeTemplatedRouter from './AdobeTemplatedRouter';
import AdobeSaasRouter from './AdobeSaasRouter';
import LibertyRouter from './LibertyRouter';
import ATHRouter from './ATHRouter';
import AllPlatformsRumTable from '../../containers/AllPlatformsRumTable';
import RumLeaderboard from '../../containers/RumLeaderboard';
import OnDemandFormContainer from '../../containers/on-demand/OnDemandFormContainer'
import Login from '../auth/Login';
import { connect } from 'react-redux';
import { decode } from 'jsonwebtoken';
import { Actions } from '../../store/actions';
import PropTypes from 'prop-types';

function Main (props) {

  function checkAlreadyLoggedIn () {
    const sessionLoginData = sessionStorage.getItem('loginData')
    if (sessionLoginData) {
      const { email, exp } = decode(sessionLoginData);
      if (Date.now() < exp * 1000) {
        const loginData = {
          loggedInEmail: email,
          isLoggedIn: true
        }
        props.setLogin(loginData)
        return true
      }
    }
    return false
  }

  return (
    <Switch>
      <Route exact path="/" render={(props) =>
        checkAlreadyLoggedIn() ? <Home {...props} />
          : <Login {...props} redirectTo="/" />
      } />
      <Route path="/login" component={Login} />
      {/* <Route path="/adobe-classic" render={(props) =>
        checkAlreadyLoggedIn() ? <AdobeClassicRouter {...props} />
          : <Login {...props} redirectTo="/adobe-classic" />
      } /> */}

      <Route path="/adobe-saas" render={(props) =>
        checkAlreadyLoggedIn() ? <AdobeSaasRouter {...props} />
          : <Login {...props} redirectTo="/adobe-saas" />
      } />
      <Route path="/liberty" render={(props) =>
        checkAlreadyLoggedIn() ? <LibertyRouter {...props} />
          : <Login {...props} redirectTo="/liberty" />
      } />
      <Route path="/wp-lite" render={(props) =>
        checkAlreadyLoggedIn() ? <WpLiteRouter {...props} />
          : <Login {...props} redirectTo="/wp-lite" />
      } />
      <Route path="/ath" render={(props) =>
        checkAlreadyLoggedIn() ? <ATHRouter {...props} />
          : <Login {...props} redirectTo="/ath" />
      } />
      {/* <Route path="/adobe-templated" render={(props) =>
        checkAlreadyLoggedIn() ? <AdobeTemplatedRouter {...props} />
          : <Login {...props} redirectTo="/adobe-templated" />
      } /> */}

      <Route path="/rum-leaderboard" render={(props) =>
        checkAlreadyLoggedIn() ? <RumLeaderboard {...props} />
          : <Login {...props} redirectTo="/rum-leaderboard" />
      } />
      <Route path="/on-demand/lighthouse/" render={(props) =>
        checkAlreadyLoggedIn() ? <OnDemandFormContainer {...props} />
          : <Login {...props} redirectTo="/on-demand/lighthouse/" />
      } />

      <Route
        path="/all/rum-table"
        render={(props) =>
          checkAlreadyLoggedIn() ? <AllPlatformsRumTable {...props} />
            : <Login {...props} redirectTo="/all/rum-table" />
        } />
      <Route component={NoMatch} />
    </Switch>
  );
}

Main.propTypes = {
  appData: PropTypes.object,
  setLogin: PropTypes.func,
}

function mapStateToProps (state) {
  return {
    appData: state.appData
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setLogin (loginData) {
      dispatch(Actions.setLogin(loginData));
    }
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Main));