import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import RumTable from '../../containers/liberty/RumTable';
// import RumKeyPagesTable from '../../containers/liberty/RumKeyPages';
// eslint-disable-next-line no-unused-vars
import SEOHealthReport from '../../containers/liberty/SEOHealthReport';
import BrandMetaData from '../../containers/liberty/BrandMetaData';
import LighthouseKeyPagesTable from '../../containers/liberty/LighthouseKeyPagesTable';
import NoMatch from '../MessageComponents/NoMatch';
import RumChartsNotAvailable from '../MessageComponents/RumChartsNotAvailable';
import LighthouseTestRuns3g from '../../containers/LighthouseTestRuns3g';
import LighthouseTestRunsCable from '../../containers/LighthouseTestRunsCable';
import LighthouseAggregatedRuns3g from '../../containers/LighthouseAggregatedRuns3g';
import LighthouseAggregatedRunsCable from '../../containers/LighthouseAggregatedRunsCable';
import SEOHealthSitemapScreen from '../../containers/SEOHealthSitemapScreen';
import SEOHealthInspectionScreen from '../../containers/SEOHealthInspectionScreen';
import { connect } from 'react-redux';
import ComingSoon from '../MessageComponents/ComingSoon';

function LibertyRouter ({ match }) {

  return (
    <Switch>
      <Route exact path={`${match.url}/`}>
        <Redirect exact to={`${match.url}/rum-table`} />
      </Route>
      <Route exact path={`${match.url}/rum-table`} component={RumTable} />
      <Route
        exact
        path={`${match.url}/rum`}
        component={RumChartsNotAvailable}
      />
      {/* <Route
        exact
        path={`${match.url}/rum-table-key-pages`}
        component={RumKeyPagesTable}
      /> */}
      <Route
        exact
        path={`${match.url}/seo-health`}
        component={ComingSoon}
      />
      <Route
        exact
        path={`${match.url}/seo-health-sitemap-runs`}
        component={SEOHealthSitemapScreen}
      />
      <Route
        exact
        path={`${match.url}/seo-health-inspection-runs`}
        component={SEOHealthInspectionScreen}
      />
      <Route
        exact
        path={`${match.url}/seo-health/seo-health-sitemap-runs`}
        component={SEOHealthSitemapScreen}
      />
      <Route
        exact
        path={`${match.url}/seo-health/seo-health-inspection-runs`}
        component={SEOHealthInspectionScreen}
      />

      <Route
        exact
        path={`${match.url}/lighthouse`}
        component={LighthouseKeyPagesTable}
      />
      <Route
        exact
        path={`${match.url}/lighthouse-test-runs-3g`}
        component={LighthouseTestRuns3g}
      />
      <Route
        exact
        path={`${match.url}/lighthouse/lighthouse-test-runs-3g`}
        component={LighthouseTestRuns3g}
      />
      <Route
        exact
        path={`${match.url}/lighthouse-test-runs-cable`}
        component={LighthouseTestRunsCable}
      />
      <Route
        exact
        path={`${match.url}/lighthouse/lighthouse-test-runs-cable`}
        component={LighthouseTestRunsCable}
      />
      <Route
        exact
        path={`${match.url}/lighthouse-aggregated-runs-3g`}
        component={LighthouseAggregatedRuns3g}
      />
      <Route
        exact
        path={`${match.url}/lighthouse/lighthouse-aggregated-runs-3g`}
        component={LighthouseAggregatedRuns3g}
      />
      <Route
        exact
        path={`${match.url}/lighthouse-aggregated-runs-cable`}
        component={LighthouseAggregatedRunsCable}
      />
      <Route
        exact
        path={`${match.url}/lighthouse/lighthouse-aggregated-runs-cable`}
        component={LighthouseAggregatedRunsCable}
      />
      <Route exact path={`${match.url}/data`} component={BrandMetaData} />
      <Route component={NoMatch} />
    </Switch>
  );
}

LibertyRouter.propTypes = {
  match: PropTypes.object,
};

export default connect()(LibertyRouter);
