/* eslint-disable indent */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../store/actions';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import moment from 'moment';
// import { Button } from 'semantic-ui-react';

const columns = [
  {
    Header: 'Date',
    accessor: 'timestamp',
    Cell: row => {
      return moment(row.value).toString();
    }
  },
  {
    id: 'inspection report',
    Header: 'Page Urls Report',
    Cell: row => {
      const domain = encodeURIComponent(row.original.domain);
      return (
        <a href={`seo-health-inspection-runs?page=${domain}`}>Report</a>
      )
    },
    filterable: false,
    aggregate: vals => vals[0],
    getProps: () => {
      return {
        style: {
          textAlign: 'center'
        }
      };
    }
  },
  {
    Header: '',
    headerStyle: {
      'borderRight': '3px solid rgba(0,0,0,0.15)',
      'borderLeft': '3px solid rgba(0,0,0,0.15)'
    },
    columns: [
      {
        Header:() => {
          return (
            <div>
              {'Sitemap Url'}
            </div>
          );
        },
        accessor: 'sitemap',
        Cell: ({ value }) => {
          return value.map((sitemap, index) => (
            <div key={index}>
              <a href={sitemap.path} target="_blank" rel="noopener noreferrer">
                {sitemap.path}
              </a>
            </div>
          ));
        },
        headerStyle: { 'borderLeft': '3px solid rgba(0,0,0,0.15)' },
        filterAll: true,
        getProps: () => {
          let colour = 'black';
          let fontWeight = 'normal';
          let fontSize = '14px';
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize,
              borderLeft: '3px solid rgba(0,0,0,0.15)'
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <div>
              {'Total Pages'}
            </div>
          );
        },
        accessor: 'totalPages',
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'normal';
          let fontSize = '14px';
          if (rowInfo) {
            if (
              rowInfo.row['totalPages'] === 0 ||
              rowInfo.row['totalPages'] === undefined ||
              rowInfo.row['totalPages'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <div>
              {'Indexed Pages'}
            </div>
          );
        },
        accessor: 'indexedPages',
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'normal';
          let fontSize = '14px';
          if (rowInfo) {
            if (
              rowInfo.row['indexedPages'] === 0 ||
              rowInfo.row['indexedPages'] === undefined ||
              rowInfo.row['indexedPages'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <div>
              {'Errors'}
            </div>
          );
        },
        accessor: 'sitemap',
        Cell: ({ value }) => {
          return value.map((sitemap, index) => (
            <div key={index}>{sitemap.errors}</div>
          ));
        },
        getProps: () => {
          let colour = 'black';
          let fontWeight = 'normal';
          let fontSize = '14px';
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize,
            }
          };
        },
        getHeaderProps: () => {
          return {
            style: {
              marginRight: '10px solid'
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <div>
              {'Warnings'}
            </div>
          );
        },
        accessor: 'sitemap',
        Cell: ({ value }) => {
          return value.map((sitemap, index) => (
            <div key={index}>{sitemap.warnings}</div>
          ));
        },
        getProps: () => {
          let colour = 'black';
          let fontWeight = 'normal';
          let fontSize = '14px';
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <div>
              {'isPending'}
            </div>
          );
        },
        accessor: 'sitemap',
        Cell: ({ value }) => {
          return value.map((sitemap, index) => (
            <div key={index}>{sitemap.isPending == false ? "false" : "true"}</div>
          ));
        },
        getProps: () => {
          let colour = 'black';
          let fontWeight = 'normal';
          let fontSize = '14px';
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <div>
              {'type of xml'}
            </div>
          );
        },
        accessor: 'sitemap',
        Cell: ({ value }) => {
          return value.map((sitemap, index) => (
            <div key={index}>{sitemap.type == undefined ? 'N/A' : sitemap.type}</div>
          ));
        },
        getProps: () => {
          let colour = 'black';
          let fontWeight = 'normal';
          let fontSize = '14px';
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <div>
              {'lastSubmitted'}
            </div>
          );
        },
        accessor: 'sitemap',
        Cell: ({ value }) => {
          return value.map((sitemap, index) => (
            <div key={index}>{moment(sitemap.lastSubmitted).toString()}</div>
          ));
        },
        getProps: () => {
          let colour = 'black';
          let fontWeight = 'normal';
          let fontSize = '14px';
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <div>
              {'lastDownloaded'}
            </div>
          );
        },
        accessor: 'sitemap',
        Cell: ({ value }) => {
          return value.map((sitemap, index) => (
            <div key={index}>{moment(sitemap.lastDownloaded).toString()}</div>
          ));
        },
        getProps: () => {
          let colour = 'black';
          let fontWeight = 'normal';
          let fontSize = '14px';
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      }
    ]
  }
];

class SEOHealthSitemapScreen extends Component {
  constructor() {
    super();
    this.state = {
      loading: true
    };
  }
  componentDidMount() {
    const url = this.props.location.search.split('=')[1];
    this.setState({ url });
    const initialPath = this.props.location.pathname.split('/')[1];
    let platform = initialPath;
    if (initialPath.includes('-')) {
      platform = initialPath.split('-')[1];
    }
    this.props.fetchRawData(platform, url);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        loading: false
      });
    }
  }
  async getCsv() {
    const csv = await this.generateCsv();
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.download = `gsc_seo_sitemap_runs.csv`;
    link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    link.click();
  }
  async generateCsv() {
    let data = this.props.data;
    let csv = '';
    let features = ['Date','Brand','Locale','SitemapUrl','totalUrl', 'Indexed', 'Errors','Warnings','isPending', 'type of sitemap', 'lastSubmitted','lastDownloaded'];
    for (let feature of features) {
      csv = `${csv}${feature},`;
    }
    csv = `${csv}\r\n`;
    for (let item of data) {
      for (let feature of features) {
        csv = `${csv}${item[feature]},`;
      }
      csv = `${csv}\r\n`;
    }
    return csv;
  }
  render() {
    let data =
      this.props.data && this.props.data.length > 0
        ? this.props.data.asMutable()
        : [];
    return (
      <div style={{ padding: '20px', height: '100%' }}>
        <div style={{ display: 'inline-block', width: '100%' }}>
          <h1 style={{ display: 'inline-block' }}>
            Google Search Console Sitemap runs for {decodeURIComponent(this.state.url)}
          </h1>
          {/* <Button
            style={{ float: 'right' }}
            basic
            disabled={this.props.loading}
            loading={this.props.loading}
            icon="download"
            content="Download CSV"
            onClick={this.getCsv.bind(this)}
          /> */}
          <div>
          </div>
        </div>
        <ReactTable
          loading={this.props.loading}
          data={data}
          columns={columns}
          pageSize={data.length}
          showPagination={false}
          showPageSizeOptions={false}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRawData(platform, domain) {
      dispatch(Actions.fetchSEOHealthSitemapDataForPlatform(platform, domain));
    }
  };
}

function mapStateToProps(state) {
  return {
    data: state.SeoHealthForPlatform.rawSitemapDataForDomain.data,
    loading: state.SeoHealthForPlatform.rawSitemapDataForDomain.loading
  };
}
SEOHealthSitemapScreen.propTypes = {
  data: PropTypes.array,
  fetchRawData: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  loading: PropTypes.bool
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SEOHealthSitemapScreen);
