import Immutable from 'seamless-immutable';

const defaultState = {
  adobeClassic: {
    metrics: Immutable({
      loading: true,
      data: {},
      error: null
    })
  },
  wpLite: {
    rumData: Immutable({
      loading: true,
      data: [],
      summaryData: {},
      error: null
    }),
    rumLeaderboard: Immutable({
      loading: true,
      data: [],
      error: null
    }),
    rumKeyPagesData: Immutable({
      loading: true,
      data: [],
      summaryData: {},
      error: null
    }),
    brandMetaData: Immutable({
      loading: true,
      data: [],
      error: null
    }),
    metrics: Immutable({
      loading: true,
      data: {},
      error: null
    })
  },
  adobeSaas: {
    rumData: Immutable({
      loading: true,
      data: [],
      summaryData: {},
      error: null
    }),
    rumLeaderboard: Immutable({
      loading: true,
      data: [],
      error: null
    }),
    rumKeyPagesData: Immutable({
      loading: true,
      data: [],
      summaryData: {},
      error: null
    }),
    brandMetaData: Immutable({
      loading: true,
      data: [],
      error: null
    }),
    metrics: Immutable({
      loading: true,
      data: {},
      error: null
    })
  },
  ath: {
    rumData: Immutable({
      loading: true,
      data: [],
      summaryData: {},
      error: null
    }),
    rumLeaderboard: Immutable({
      loading: true,
      data: [],
      error: null
    }),
    rumKeyPagesData: Immutable({
      loading: true,
      data: [],
      summaryData: {},
      error: null
    }),
    brandMetaData: Immutable({
      loading: true,
      data: [],
      error: null
    }),
    metrics: Immutable({
      loading: true,
      data: {},
      error: null
    })
  },
  liberty: {
    rumData: Immutable({
      loading: true,
      data: [],
      summaryData: {},
      error: null
    }),
    rumLeaderboard: Immutable({
      loading: true,
      data: [],
      error: null
    }),
    rumKeyPagesData: Immutable({
      loading: true,
      data: [],
      summaryData: {},
      error: null
    }),
    brandMetaData: Immutable({
      loading: true,
      data: [],
      error: null
    }),
    metrics: Immutable({
      loading: true,
      data: {},
      error: null
    })
  },
  adobeTemplated: {
    rumData: Immutable({
      loading: true,
      data: [],
      summaryData: {},
      error: null
    }),
    rumLeaderboard: Immutable({
      loading: true,
      data: [],
      error: null
    }),
    rumKeyPagesData: Immutable({
      loading: true,
      data: [],
      summaryData: {},
      error: null
    }),
    brandMetaData: Immutable({
      loading: true,
      data: [],
      error: null
    }),
    metrics: Immutable({
      loading: true,
      data: {},
      error: null
    })
  },
  anomalyDetection: {
    anomalyDailyReport: Immutable({
      loading: true,
      anomalyData: [],
      error: null
    })
  },
  cxxSites: Immutable({
    loading: true,
    data: [],
    summaryData: {},
    error: null
  }),

  competitor: {
    brandMetaData: Immutable({
      loading: true,
      data: [],
      error: null
    })
  },
  brandRumData: Immutable({
    pageLoadData: [],
    pageLoadBrandName: '',
    pageLoadLoading: true
  }),
  d2Apps: Immutable({
    data: [],
    loading: true
  }),
  rumData: Immutable({
    pageLoadData: [],
    pageLoadBrandName: '',
    pageLoadLoading: true,
    devicesLoading: true,
    devicesData: []
  }),

  chartData: Immutable({
    loading: false,
    appIds: [],
    charts: {}
  }),

  synthetic: {
    keyPagesData: Immutable({
      loading: false,
      data: {},
      error: null
    }),
    keyPagesSummary: Immutable({
      loading: false,
      data: {},
      error: null
    }),
    competitorKeyPages: Immutable({
      loading: false,
      data: {},
      error: null
    }),
    competitorKeyPagesSummary: Immutable({
      loading: false,
      data: {},
      error: null
    })
  },
  syntheticForPlatform: {
    keyPagesData: Immutable({
      loading: false,
      data: {},
      classicData: {},
      saasData: {},
      templatedData: {},
      liteData: {},
      athData: {},
      libertyData: {},
      competitorData: {},
      error: null
    }),
    keyPagesMonthData: Immutable({
      loading: false,
      data: {},
      error: null
    }),
    rawDataForUrl: Immutable({
      loading: false,
      data: [],
      error: null
    })
  },

  SeoHealthForPlatform: {
    keyPagesData: Immutable({
      loading: false,
      saasData: [],
      saasSummaryData: {},
      liteData: [],
      liteSummaryData: {},
      athData: [],
      athSummaryData: {},
      libertyData: [],
      libertySummaryData: {},
      error: null
    }),
    rawSitemapDataForDomain: Immutable({
      loading: false,
      data: [],
      error: null
    }),
    rawInspectionDataForDomain: Immutable({
      loading: false,
      data: [],
      error: null
    }),
  },

  lighthouseForPlatform: {
    keyPagesData: Immutable({
      loading: false,
      data: {},
      classicData: {},
      saasData: {},
      templatedData: {},
      liteData: {},
      athData: {},
      libertyData: {},
      competitorData: {},
      error: null
    }),
    keyPagesMonthData: Immutable({
      loading: false,
      data: {},
      error: null
    }),
    rawDataForUrl: Immutable({
      loading: false,
      data: [],
      error: null
    }),
    SummaryDataForBrandAndLocale: Immutable({
      loading: false,
      data: {},
      error: null
    })
  },

  selfService: Immutable({
    loading: false,
    data: [],
    error: null
  }),
  selfServiceSummary: Immutable({
    loading: false,
    data: [],
    error: null
  }),

  rum: {
    current: Immutable({
      average: null,
      sitesCount: null,
      items: []
    }),
    categories: Immutable({
      loading: false,
      data: [],
      error: null,
      charts: {}
    }),
    brands: Immutable({
      loading: false,
      data: [],
      error: null
    }),
    agencies: Immutable({
      loading: false,
      data: [],
      error: null
    }),
    subCategories: Immutable({
      loading: false,
      data: [],
      error: null
    }),
    MCOs: Immutable({
      loading: false,
      data: [],
      error: null
    }),
    countries: Immutable({
      loading: false,
      data: [],
      error: null
    }),
    clusters: Immutable({
      loading: false,
      data: [],
      error: null
    }),
    brandMetadata: Immutable({
      loading: false,
      data: [],
      error: null
    }),
    appMetadata: Immutable({
      loading: true,
      data: {},
      error: null
    }),
    rumLeaderboard: Immutable({
      loading: true,
      data: [],
      error: null
    }),
    appMetadataKeyPages: Immutable({
      loading: true,
      data: {},
      error: null
    })
  }
};

export default defaultState;
