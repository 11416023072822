import { combineReducers } from 'redux';
import keyPagesData from './keyPagesData';
import rawSitemapDataForDomain from './rawSitemapDataForDomain';
import rawInspectionDataForDomain from './rawInspectionDataForDomain';

export default combineReducers({
  keyPagesData,
  rawSitemapDataForDomain,
  rawInspectionDataForDomain
});
