import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { Loader, Checkbox, Header, Button } from 'semantic-ui-react';
import Navigation from '../../components/Navigation';
import { sitesNavItems } from '../../helpers/platformNavItems';
import brandDataNavItems from '../../helpers/brandDataNavItems';
import TableColumnDropDown from '../../components/TableColumnDropDown';
import {
  BrandMetadataTableColumns,
  BrandMetaTableColumnData
} from '../../helpers/tableHelpers';

class BrandMetaData extends React.PureComponent {
  state = {
    showAll: false,
    filtered: [],
    brandValue: [],
    localeValue: [],
    subCategoryValue: [],
    categoryValue: [],
    clusterValue: [],
    mcoValue: [],
    countryValue: [],
    agencyValue: [],
    localeFilters: [],
    brandFilters: [],
    categoryFilters: [],
    subCategoryFilters: [],
    clusterFilters: [],
    mcoFilters: [],
    countryFilters: [],
    agencyFilters: [],
    parentFilters: [],
    priorityFilters: [],
    parentValue: [],
    priorityValue: [],
  };

  componentDidMount() {
    if (this.props.location) {
      const initialPath = this.props.location.pathname.split('/')[1];
      let platform = initialPath;
      if (initialPath.includes('-')) {
        platform = initialPath.split('-')[1];
      }
      this.setState({ platform });
    }
    this.props.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setDropDownStates(this.props.data);
    }
  }

  getFiltersForType(data, type) {
    const uniqueItems = [...new Set(data.map(i => i[type]))].sort();
    const dropDownItems = uniqueItems.map((item, index) => {
      return {
        key: `${index}-${item}`,
        value: item,
        text: item
      };
    });
    return dropDownItems;
  }

  setDropDownStates(data) {
    const brandFilters = this.getFiltersForType(data, 'Brand');
    const localeFilters = this.getFiltersForType(data, 'Locale');
    const categoryFilters = this.getFiltersForType(data, 'SuperCategory');
    const subCategoryFilters = this.getFiltersForType(data, 'SubCategory');
    const clusterFilters = this.getFiltersForType(data, 'Cluster');
    const mcoFilters = this.getFiltersForType(data, 'MCO');
    const countryFilters = this.getFiltersForType(data, 'Country');
    const agencyFilters = this.getFiltersForType(data, 'Agency');
    const parentFilters = this.getFiltersForType(data, 'Parent');
    const priorityFilters = this.getFiltersForType(data, 'Priority');

    this.setState({
      brandFilters,
      localeFilters,
      categoryFilters,
      subCategoryFilters,
      clusterFilters,
      mcoFilters,
      countryFilters,
      agencyFilters,
      parentFilters,
      priorityFilters
    });
  }

  getColumnDropdown(selectText, filtersName, filtersValue, type) {
    return () => (
      <TableColumnDropDown
        placeholder={selectText}
        options={this.state[filtersName]}
        onChange={this.selectItem}
        value={this.state[filtersValue]}
        type={type}
        valueProperty={filtersValue}
      />
    );
  }

  clearFilters() {
    this.setState({
      filtered: [],
      brandValue: [],
      localeValue: [],
      subCategoryValue: [],
      categoryValue: [],
      clusterValue: [],
      mcoValue: [],
      countryValue: [],
      agencyValue: [],
      parentValue: [],
      priorityValue: [],
    });
  }

  updateCurrentFilters(data, type){
    let existingFilter = false;
    let filters = this.state.filtered.map(el => {
      if (el.id === type) {
        el.value = data.value;
        existingFilter = true;
      }
      return el;
    });

    if (existingFilter === false) {
      filters.push(data);
    }
    return filters;
  }

  selectItem = (event, data) => {
    const type = data[`data-type`];
    const valueProperty = data[`data-valueproperty`];
    if (data.value.length > 0) {
      this.setState({
        filtered: this.updateCurrentFilters(
          { id: type, value: data.value },
          type
        ),
        [valueProperty]: data.value
      });
    } else {
      if (event.target.className === 'delete icon') {
        let parentValue = event.target.parentElement.getAttribute('value');
        this.setState({
          filtered: this.state.filtered.filter(el => {
            return el.id !== type && el.value !== data.value;
          }),
          [valueProperty]: this.state[valueProperty].filter(el => {
            return el !== parentValue;
          })
        });
      }
    }
  };

  getColumns() {
    const columnsEndIndex = this.state.showAll
      ? BrandMetadataTableColumns.length
      : 7;
    const dataEndIndex = this.state.showAll
      ? BrandMetaTableColumnData.length
      : 7;
    const columns = BrandMetadataTableColumns.slice(0, columnsEndIndex);
    for (let i = 0; i < dataEndIndex; i++) {
      const colData = BrandMetaTableColumnData[i];
      const column = columns.find(i => i.id === colData.type);
      column.Filter = this.getColumnDropdown(
        colData.selectText,
        colData.filtersName,
        colData.filtersValue,
        colData.type
      );
    }
    return columns;
  }

  makeCSV = () => {
    const { data } = this.props;
  
    const headers = Array.from(
      new Set(
        data.flatMap(app => Object.keys(app)).filter(key => key !== 'TopPages' && key !== 'KeyPages' && key !== 'Agency' && key !== 'IdSiteKey' && key !== 'IsCxx')
      )
    );
  
    const dataForCsv = data.map(app => {
      const cleanedApp = { ...app };
      delete cleanedApp.PerceivedLoadMarkers;
      delete cleanedApp.TopPages; 
      delete cleanedApp.KeyPages;
      delete cleanedApp.Agency
      delete cleanedApp.IdSiteKey
      delete cleanedApp.IsCxx
  
      if (cleanedApp.MCO) {
        cleanedApp.MCO = cleanedApp.MCO.replace(',', '');
      }
  
      const row = headers.map(header => (cleanedApp[header] !== undefined ? cleanedApp[header] : ''));
      return row;
    });
  
    const csvContent = [
      headers.join(','), 
      ...dataForCsv.map(row => row.join(','))
    ].join('\n');
  
    return csvContent;
  };
  

  getFile = () => {
    if (!this.props.loading && this.props.data.length > 0) {
      const csv = this.makeCSV();
      const fileName = `${this.state.platform}-sites.csv`;
      return (
        <a
          download={fileName}
          href={
            'data:application/csv;charset=utf-8,\uFEFF' +
            encodeURIComponent(csv)
          }
        >
          <Button basic icon="download" content="Download CSV" />
        </a>
      );
    } else {
      return <Button loading basic icon="download" content="Download CSV" />;
    }
  };

  toggleColumns = () => {
    const showAll = !this.state.showAll;
    this.setState({ showAll });
  };

  render() {
    const isAdobeClassic = this.props.prefix === 'adobe-classic';
    const columns = this.getColumns();
    const data = this.props.loading ? [] : [...this.props.data];
    return (
      <span>
        <Navigation activeItem={this.state.platform} items={sitesNavItems} />
        {isAdobeClassic && (
          <Navigation activeItem="data" items={brandDataNavItems} />
        )}

        <div style={{ padding: '20px' }}>
          <div className="brand-table-header">
            <Header as="h1">Sites for platform</Header>
            <span>{this.getFile()}</span>
          </div>
          <ReactTable
            loading={this.props.loading}
            data={data}
            columns={columns}
            filterable
            className="-striped -highlight rum-table"
            filtered={this.state.filtered}
            showPagination={false}
            pageSize={data.length}
            defaultSorted={[{ id: 'Brand' }, { id: 'Locale' }]}
          >
            {(state, makeTable) => {
              return (
                <div>
                  <div className="brand-table-header">
                    <h3>{state.sortedData.length} sites</h3>
                    <span>
                      <Checkbox
                        label="Show all columns"
                        onClick={this.toggleColumns}
                      />
                    </span>
                  </div>
                  {makeTable()}
                  {this.props.loading && <Loader active />}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </span>
    );
  }
}

BrandMetaData.propTypes = {
  prefix: PropTypes.string,
  data: PropTypes.array,
  loading: PropTypes.bool,
  fetchData: PropTypes.func,
  location: PropTypes.object
};

BrandMetaData.defaultProps = {
  data: []
};

export default BrandMetaData;
