import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { RED, GREEN, AMBER } from '../../helpers/constants';
import { Button } from 'semantic-ui-react';
import Filter from '../../components/Filter';

const ROW_TITLES = [
  'Green',
  'Amber',
  'Red',
  'total',
  '% in acceptable threshold'
];
const ROW_COLOURS = [GREEN, AMBER, RED, 'black', 'black'];

class SeoHealthSummaryTable extends React.PureComponent {
  state = { display: 'Months' };

  getFilters () {
    return [
      {
        value: 'Months',
        text: 'Monthly',
        id: 0
      },
      {
        value: 'Weeks',
        text: 'Weekly',
        id: 1
      },
      {
        value: 'Days',
        text: 'Daily',
        id: 2
      } 
    ];
  }
  onFilterChange = display => {
    this.setState({ display });
  };
  getColumns (summaryData) {
    const filter = this.state.display;

    // reverse data to show most recent on the left of the table
    const dataForTimePeriod = summaryData[filter].asMutable();
    return [
      {
        Header: 'Threshold',
        accessor: 'title',
        getProps: (state, rowInfo) => {
          let colour = 'black';
          if (rowInfo) {
            colour = ROW_COLOURS[rowInfo.index];
          }
          return {
            style: {
              color: colour,
              fontWeight: 'bolder'
            }
          };
        }
      },

      ...dataForTimePeriod.map(timePeriod => {
        return {
          Header: timePeriod.header,
          id: timePeriod.date,
          accessor: d => d[timePeriod.date],
          getProps: (state, rowInfo) => {
            let colour;
            switch (rowInfo.index) {
              case 0:
                colour = GREEN;
                break;
              case 1:
                colour = AMBER;
                break;
              case 2:
                colour = RED;
                break;
              default:
                colour = 'black';
            }
            return {
              style: {
                color: colour,
                fontWeight: 'bold'
              }
            };
          }
        };
      })
    ];
  }

  convertToCSV(data) {
    const thresholds = ['green', 'amber', 'red', 'total', 'acceptable'];
    let weeks = data.Weeks.asMutable().slice();
    let months = data.Months.asMutable().slice();
    let days = data.Days.asMutable().slice();

    const daysHeaders = days.map(day => day.header).join(',')
    const weekHeaders = weeks.map(week => week.header).join(',');
    const monthHeaders = months.map(month => month.header).join(',');

    const csvRows = [
      `Threshold,${daysHeaders},${monthHeaders},${weekHeaders}`
    ];

    thresholds.forEach(threshold => {
      const dailyValues = days.map(day => day[threshold]).join(',');
      const monthlyValues = months.map(month => month[threshold]).join(',');
      const weeklyValues = weeks.map(week => week[threshold]).join(',');
      csvRows.push(`${threshold},${dailyValues},${monthlyValues},${weeklyValues}`);
    });

    return csvRows.join('\n');
  }

  downloadCSV() {
    let data = this.props.summaryData;
    let filename = `${this.props.prefix}-seo-gsc-summary.csv`;
    const csvData = this.convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    a.click();

    window.URL.revokeObjectURL(url);
  }

  render () {
    const { summaryData, rowHeaders } = this.props;
    let data = [];
    let columns = [];
    const filter = this.state.display;

    if (summaryData && Object.keys(summaryData).length > 0) {
      data = rowHeaders.map((rowHeader, index) => {
        const row = {};
        row.title = ROW_TITLES[index];
        summaryData[filter].forEach(item => {
          row[item.date] = item[rowHeader];
        });
        return row;
      });
      columns = this.getColumns(summaryData);
    }
    return (
      <div className="rum-summary-table">
        <div style={{
          "display": "flex",
          "justifyContent": "space-between"
        }}>
          <div >
            <Filter
              data={this.getFilters()}
              value={this.state.display}
              onChange={this.onFilterChange}
            />
          </div>
          <div >
            <span>
              <Button
                basic
                disabled={this.props.loading}
                loading={this.props.loading}
                icon="download"
                content="Download CSV"
                onClick={this.downloadCSV.bind(this)}
              />
            </span>
          </div>
        </div>
        <ReactTable
          pageSize={data.length}
          columns={columns}
          data={data}
          showPagination={false}
          showPageSizeOptions={false}
          sortable={false}
          loading={this.props.loading}
        />
      </div>
    );
  }
}

SeoHealthSummaryTable.propTypes = {
  prefix: PropTypes.string,
  summaryData: PropTypes.object,
  rowHeaders: PropTypes.array,
  loading: PropTypes.bool
};

SeoHealthSummaryTable.defaultProps = {
  rowHeaders: ['green', 'amber', 'red', 'total', 'acceptable'],
  // loading: false
};

export default SeoHealthSummaryTable;
