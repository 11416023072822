import React from 'react';
import SEOHealthReportTemplate from '../templates/SEOHealthReportTemplate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Actions } from '../../store/actions';

class SEOHealthReport extends React.PureComponent {
  render() {
    const {
      loading,
      keyPagesData,
      summaryData
    } = this.props;
    let data = !keyPagesData ? [] : keyPagesData;
    let summary = !summaryData ? {} : summaryData;

    return (
      <SEOHealthReportTemplate
        {...this.props}
        keyPagesData={data}
        summaryData={summary}
        loading={loading}
        fetchData={this.props.fetchData}
        prefix="liberty"
      />
    );
  }
}

SEOHealthReport.propTypes = {
  fetchData: PropTypes.func,
  keyPagesData: PropTypes.array,
  summaryData: PropTypes.object,
  location: PropTypes.object,
  loading: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    keyPagesData: state.SeoHealthForPlatform.keyPagesData.libertyData,
    summaryData: state.SeoHealthForPlatform.keyPagesData.libertySummaryData,
    loading: state.SeoHealthForPlatform.keyPagesData.loading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchData() {
      return dispatch(
        Actions.fetchSEOHealthSummaryDataForPlatform('liberty')
      );
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SEOHealthReport);
