import ActionNames from '../../actions/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: false,
  data: {},
  saasData: [],
  saasSummaryData: {},
  liteData: [],
  liteSummaryData: {},
  athData: [],
  athSummaryData: {},
  libertyData: [],
  libertySummaryData: {},
  error: null
});

function keyPagesDataReducer(state = initialState, action) {
  switch (action.type) {
    case ActionNames.REQUEST_GSC_SEO_HEALTH_SUMMARY_FOR_PLATFORM:
      return state.merge({ data: {}, loading: true });
    case ActionNames.RECEIVE_GSC_SEO_HEALTH_SUMMARY_FOR_PLATFORM:
      return state.merge({loading: false, [`${action.platform}Data`]: action.data, [`${action.platform}SummaryData`]: action.summaryData});
    case ActionNames.FAILURE_GSC_SEO_HEALTH_SUMMARY_FOR_PLATFORM:
      return state.merge({ error: action.error, loading: false });
    default:
      return state;
  }
}

export default keyPagesDataReducer;
