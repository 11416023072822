import { combineReducers } from 'redux';
import brandRumData from './brandRumData.js';
import d2Apps from './d2Apps';
import rumData from './rumData';
import rum from './rum';
import chartData from './chartData';
import synthetic from './synthetic';
import wpLite from './wpLite';
import adobeTemplated from './adobeTemplated';
import adobeSaas from './adobeSaas';
import ath from './ath';
import liberty from './liberty';
import competitor from './competitor';
import syntheticForPlatform from './syntheticForPlatform';
import lighthouseForPlatform from './lighthouse';
import SeoHealthForPlatform from './seohealth';
import adobeClassic from './adobeClassic';
import anomalyDetection from './anomalyDetection';
import selfService from './selfService';
import selfServiceSummary from './selfServiceSummary';
import selfServiceMobile from './selfServiceMobile';
import allMetrics from './allMetrics';
import cxxSites from './cxxSitesPage';
import appData from './appData';

export default combineReducers({
  brandRumData,
  d2Apps,
  rumData,
  rum,
  chartData,
  synthetic,
  wpLite,
  adobeTemplated,
  competitor,
  anomalyDetection,
  syntheticForPlatform,
  lighthouseForPlatform,
  SeoHealthForPlatform,
  adobeClassic,
  adobeSaas,
  ath,
  liberty,
  selfService,
  selfServiceSummary,
  selfServiceMobile,
  allMetrics,
  cxxSites,
  appData
});
