/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import {
  Divider,
  Checkbox,
  Button,
  Dropdown,
  Header,
  Icon
} from 'semantic-ui-react';
import _ from 'lodash';
import { json2csvAsync } from 'json-2-csv';

import SeoHealthSummaryTable from './SeoHealthSummaryTable';
import { getAverageColour } from '../../helpers/averageSeoGsc';
import Filter from '../../components/Filter';
import Navigation from '../../components/Navigation';
import { SEOHealthItems } from '../../helpers/platformNavItems';
import TableColumnDropDown from '../../components/TableColumnDropDown';
import {
  getCsvOptionsSeoGsc,
  getSeoHealthHeaders
} from '../../helpers/rumTableHelpers';
import moment from 'moment';
import List from '../../components/List';

let columns = [
  {
    Header: 'Domain',
    accessor: 'domain',
    filterable: false,
    Cell: row => {
      return (
        <a href={`${row.value}`} rel="noopener noreferrer" target="_blank">
          {row.value}
        </a>
      );
    },
    getProps: (state, rowInfo, column) => {
      return {
        style: {
          fontWeight: '700'
        }
      };
    }
  },
  {
    Header: 'AppId',
    accessor: 'appId',
    filterable: false,
    getProps: (state, rowInfo, column) => {
      return {
        style: {
          fontWeight: '300',
          fontSize: '13px'
        }
      };
    }
  },
  {
    Header: 'Brand',
    accessor: 'brand',
    filterAll: true,
    Cell: row => {
      return row.value;
    },
    filterMethod: (filter, rows) => {
      if (filter.value) {
        return rows.filter(i => filter.value.includes(i.brand));
      } else {
        return rows;
      }
    },
    aggregate: values => values[0],
    Aggregated: row => {
      if (typeof row.isExpanded === 'object') {
        return (
          <div style={{ height: '100%', fontWeight: '700' }}>{row.value}</div>
        );
      }
      return <div style={{ fontWeight: '700' }}>{row.value}</div>;
    }
  },
  {
    Header: 'Locale',
    accessor: 'locale',
    Cell: row => {
      return row.value;
    },
    filterMethod: (filter, rows) => {
      if (filter.value) {
        return rows.filter(i => filter.value.includes(i.locale));
      } else {
        return rows;
      }
    },
    filterAll: true,
    aggregate: vals => vals[0],
    Aggregated: row => {
      if (typeof row.isExpanded === 'object') {
        return (
          <div style={{ height: '100%', fontWeight: '700' }}>{row.value}</div>
        );
      }
      return <div style={{ fontWeight: '700' }}>{row.value}</div>;
    }
  },
  {
    Header: 'Category',
    accessor: 'superCategory',
    Cell: row => {
      return row.value;
    },
    filterMethod: (filter, rows) => {
      if (filter.value) {
        return rows.filter(i => filter.value.includes(i.superCategory));
      } else {
        return rows;
      }
    },
    filterAll: true,
    aggregate: vals => vals[0],
    Aggregated: row => {
      if (typeof row.isExpanded === 'object') {
        return (
          <div style={{ height: '100%', fontWeight: '700' }}>{row.value}</div>
        );
      }
      return <div style={{ fontWeight: '700' }}>{row.value}</div>;
    }
  }
];

class SEOHealthTable extends Component {
  constructor () {
    super();
    this.state = {
      date: moment().format('YYYY-MM-DD'),
      filterDisplay: 'months',
      filtered: [],
      metric: 'percentage',
      categoryFilters: [],
      categoryValue: [],
      localeFilters: [],
      localeValue: [],
      brandFilters: [],
      brandValue: [],
      data: [],
    };
  }

  getDynamicColumns () {
    const currentColumns = [...columns];
    const filter = this.state.filterDisplay;
    const daysWeeksOrMonths = this.props.keyPagesData[0][filter];
    // reverse data to show most recent on the left of the table
    currentColumns.push(
      {
        id: 'report',
        Header: 'Sitemap Report',
        Cell: row => {
          const domain = encodeURIComponent(row.row.domain);
          return (
            <a href={`seo-health-sitemap-runs?page=${domain}`}>Report</a>
          )
        },
        filterable: false,
        aggregate: vals => vals[0],
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: 'center'
            }
          };
        }
      },
    );
    daysWeeksOrMonths.forEach(timePeriod => {
      currentColumns.push({
        id: timePeriod.Date,
        Header: timePeriod.Header,
        accessor: d => {
          const item = d[filter].find(i => i.Date === timePeriod.Date);
          const metric = item ? item[this.state.metric] : 0;
          return metric === 0 ? '-' : metric;
        },
        Cell: row => {
          if (!isNaN(parseFloat(row.value))) {
            return <span>{_.round(parseFloat(row.value), 2)}%</span>;
          }
          return <span>{row.value}</span>;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'bolder';
          let fontSize = '22px';
          if (rowInfo) {
            colour = getAverageColour(rowInfo.row[timePeriod.Date]);
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        },
        aggregate: vals => {
          const numericValues = vals.filter(v => !isNaN(v));
          if (numericValues.length > 0) {
            return _.mean(numericValues);
          }
          return '-';
        },
        filterable: false
      });
    });
    return currentColumns;
  }
  getPlatform (prefix) {
    switch (prefix) {
      case 'saas':
        return 'ADOBE_SAAS';
      case 'liberty':
        return 'LIBERTY';
      case 'ath':
        return 'ATH';
      case 'lite':
        return 'WP_LITE';
      default:
        return;
    }
  }
  componentDidMount () {
    this.props.fetchData();
  }

  changeData = () => {
    this.setState({
      data: this.props.keyPagesData
    });
  };
  componentDidUpdate (prevProps) {
    if (
      prevProps.keyPagesData !== this.props.keyPagesData
    ) {
      this.setDropDownStates(this.props.keyPagesData);
      this.setState({
        data: this.props.keyPagesData
      });
    }
  }
  getFilters () {
    return [
      {
        value: 'months',
        text: 'Monthly',
        id: 0
      },
      {
        value: 'weeks',
        text: 'Weekly',
        id: 1
      },
      {
        value: 'days',
        text: 'Daily',
        id: 2
      }
    ];
  }

  onFilterChange = filterDisplay => {
    this.setState({ filterDisplay });
  };

  getFiltersForType (data, type) {
    const uniqueItems = [...new Set(data.map(i => i[type]))].sort();
    const dropDownItems = uniqueItems.map((item, index) => {
      return {
        key: `${index}-${item}`,
        value: item,
        text: item
      };
    });
    return dropDownItems;
  }

  setDropDownStates (data) {
    const localeFilters = this.getFiltersForType(data, 'locale');
    const brandFilters = this.getFiltersForType(data, 'brand');
    const categoryFilters = this.getFiltersForType(data, 'superCategory');
    this.setState({ localeFilters, brandFilters, categoryFilters });
  }

  getColumnDropdown ({ selectText, filtersName, filtersValue, type }) {
    return () => (
      <TableColumnDropDown
        placeholder={selectText}
        options={this.state[filtersName]}
        onChange={this.selectItem}
        value={this.state[filtersValue]}
        type={type}
        valueProperty={filtersValue}
      />
    );
  }

  getColumns () {
    columns[2].Filter = this.getColumnDropdown({
      selectText: 'Brand',
      filtersName: 'brandFilters',
      filtersValue: 'brandValue',
      type: 'brand'
    },);
    columns[3].Filter = this.getColumnDropdown({
      selectText: 'Locale',
      filtersName: 'localeFilters',
      filtersValue: 'localeValue',
      type: 'locale'
    },);
    columns[4].Filter = this.getColumnDropdown({
      selectText: 'Category',
      filtersName: 'categoryFilters',
      filtersValue: 'categoryValue',
      type: 'superCategory'
    });
    const dynamicColumns = this.getDynamicColumns();
    return [...dynamicColumns];
  }

  selectItem = (event, data) => {
    const type = data[`data-type`];
    const valueProperty = data[`data-valueproperty`];

    if (data.value.length > 0) {
      this.setState({
        filtered: this.updateCurrentFilters(
          { id: type, value: data.value },
          type
        ),
        [valueProperty]: data.value
      });
    } else {
      if (event.target.className === 'delete icon') {
        let parentValue = event.target.parentElement.getAttribute('value');
        this.setState({
          filtered: this.state.filtered.filter(el => {
            return el.id !== type && el.value !== data.value;
          }),
          [valueProperty]: this.state[valueProperty].filter(el => {
            return el !== parentValue;
          })
        });
      }
    }
  };

  updateCurrentFilters (data, type) {
    let existingFilter = false;
    let filters = this.state.filtered.map(el => {
      if (el.id === type) {
        el.value = data.value;
        existingFilter = true;
      }
      return el;
    });

    if (existingFilter === false) {
      filters.push(data);
    }

    return filters;
  }

  getCsvHeaders (summaryData, filter, dates) {
    const staticHeaders = getSeoHealthHeaders();
    const csvData = {
      headers: staticHeaders.join(',').concat(','),
      dateHeaders: '\n'.concat(','.repeat(staticHeaders.length))
    };

    // get dateHeaders and device headers with correct spacing
    filter.forEach(timePeriod => {
      csvData.headers += `${timePeriod.text}-Averages`;
      let newdata = '';
      if (summaryData != undefined) {
        summaryData[timePeriod.value].asMutable().forEach(data => {
          newdata += `${data.Header}, `;
          csvData.headers += ',';
          dates[timePeriod.value].push(data.Date);
        });
      }
      csvData.dateHeaders += newdata;
    });

    return csvData;
  }

  prepareDataForCsv (dates, filter, rawData) {
    const keyPagesData = _.sortBy(rawData, ['brand', 'locale']);
    let csvOptions = getCsvOptionsSeoGsc();

    const flatData = keyPagesData.map(siteData => {
      let avrgObj = { months: '', weeks: '', days: '' };

      for (const timePeriod of filter) {
        const averageArr = siteData[timePeriod.value]
          .asMutable()
          .filter(avrgs => dates[timePeriod.value].includes(avrgs.Date));
        const obj = {};
        for (const item of averageArr) {
          obj[item.Date] = item[this.state.metric];
        }

        const finalArr = dates[timePeriod.value].map(x => {
          const res = !obj[x] ? 0 : obj[x];
          return res;
        });

        avrgObj[timePeriod.value] = finalArr;
      }

      const newSiteData = Object.assign({}, siteData, {
        domain: siteData.domain,
        months: avrgObj['months'],
        weeks: avrgObj['weeks'],
        days: avrgObj['days']
      });
      return newSiteData;
    });

    return { flatData, csvOptions };
  }

  getTiers () {
    let tierSet = new Set();
    this.props.keyPagesData.forEach(item => {
      if (item.Tier !== undefined) {
        tierSet.add(item.Tier);
      }
    });
    let tierArray = [];
    tierArray.push({ text: 'All', value: 'all', key: 'all' });
    for (let item of tierSet) {
      let tierJson = {
        text: String(item),
        key: String(item),
        value: String(item)
      };
      tierArray.push(tierJson);
    }
    tierArray.sort((a, b) => a.value - b.value);
    return tierArray;
  }

  async generateCsv () {
    const { keyPagesData} = this.props;
    const filters = this.getFilters();
    let dates = { months: [], weeks: [], days: [] };

    const csvData = this.getCsvHeaders(keyPagesData[0], filters, dates);
    let csvNetworkData = keyPagesData;
    const { flatData, csvOptions } = this.prepareDataForCsv(
      dates,
      filters,
      csvNetworkData
    );
    let csv = await json2csvAsync(flatData, csvOptions);

    csv = csv
      .replace(/("\[)/gm, '')
      .replace(/(\]")/gm, '')
      .replace(/(null)/gm, '0');

    return csvData.headers + csvData.dateHeaders + '\n' + csv;
  }

  async getCsv () {
    const csv = await this.generateCsv();
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.download = `${this.props.prefix}-gsc-indexed.csv`;
    link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    link.click();
  }

  handleTierChanged = (e, { value }) => {
    if (value !== 'all') {
      let tierData = [];
      if (Number(value) === 2) {
        tierData = this.props.keyPagesData.filter(item => {
          if (item.Tier !== 1) {
            return item;
          }
        });
      } else {
        tierData = this.props.keyPagesData.filter(item => {
          if (item.Tier === Number(value)) {
            return item;
          }
        });
      }
      this.setState({ data: tierData });
    } else {
      const data = this.props.keyPagesData;
      this.setState({ data });
    }
  };


  render () {
    const allColumns = this.props.keyPagesData.length <= 0 ? [...columns] : [...this.getColumns()]
    let data = [...this.state.data]
    const tierOptions = this.props.loading ? [] : this.getTiers();

    return (
      <span>
        <Navigation activeItem={this.props.prefix} items={SEOHealthItems} />
        <div style={{ padding: '20px', height: '100%' }}>
          <div className="header-grid">
            <h1>Google search console SEO health data (Scheduled runs) </h1>
          </div>
          <SeoHealthSummaryTable
            loading={this.props.loading}
            prefix={this.props.prefix}
            summaryData={
              this.props.summaryData 
            }
          />
          <p style={{marginTop: "20px"}}>Domains having indexed pages in : Green(&gt;= 90%), Amber (50% - 89%) and Red (&lt; 50%).</p>
          <Divider />
          <ReactTable
            loading={this.props.loading}
            data={data}
            columns={allColumns}
            showPagination={false}
            showPageSizeOptions={false}
            pageSize={this.state.data.length}
            minRows={4}
            className="-striped -highlight rum-table"
            filterable
            filtered={this.state.filtered}
            defaultSorted={[
              { id: 'brand' },
              { id: 'locale' },
              { id: 'superCategory' }
            ]}
          >
            {(state, makeTable) => {
              return (
                <div>
                  <div className="header-grid">
                    <h3>
                      {state.sortedData.length} sites, Performance Score from
                      Google Search Console across Digital 2.0 sites
                    </h3>
                    <span>
                      <Button
                        basic
                        disabled={this.props.loading}
                        loading={this.props.loading}
                        icon="download"
                        content="Download CSV"
                        onClick={this.getCsv.bind(this)}
                      />
                    </span>
                  </div>
                  <Filter
                    data={this.getFilters()}
                    value={this.state.filterDisplay}
                    onChange={this.onFilterChange}
                  />
                  <div
                    className="header-cb"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      {/* <span>
                        <label>
                          Tier
                          <Dropdown
                            placeholder="All"
                            options={tierOptions}
                            selection
                            compact
                            onChange={this.handleTierChanged}
                          />
                        </label>
                      </span> */}
                    </div>
                  </div>
                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </span>
    );
  }
}

SEOHealthTable.propTypes = {
  fetchData: PropTypes.func,
  keyPagesData: PropTypes.array,
  summaryData: PropTypes.object,
  location: PropTypes.object,
  loading: PropTypes.bool,
  prefix: PropTypes.string,
};

SEOHealthTable.defaultProps = {
  keyPagesData: [],
  summaryData: {}
};

export default SEOHealthTable;
