/* eslint-disable indent */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../store/actions';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { Button } from 'semantic-ui-react';

const tableData = [
  { enum: "VERDICT_UNSPECIFIED", description: "Unknown verdict." },
  { enum: "PASS", description: 'Equivalent to Valid for the page in Search Console.' },
  { enum: "FAIL", description: 'Equivalent to Error or Invalid for the page in Search Console.' },
  { enum: "NEUTRAL", description: 'Equivalent to Excluded for the page in Search Console.' },
];

const columns = [
  {
    Header: 'PageUrl',
    accessor: 'pageUrl',
    Cell: row => {
      return(
        <a href={`${row.value}`} rel="noopener noreferrer" target="_blank">
            {row.value}
        </a>
      )
    }
  },
  // {
  //   Header: 'Last Job Run Date',
  //   accessor: 'timestamp',
  //   Cell: row => {
  //       return row.value
  //   },
  //   getProps: () => {
  //     let colour = 'black';
  //     let fontWeight = 'normal';
  //     let fontSize = '14px';
  //     return {
  //       style: {
  //         color: colour,
  //         textAlign: 'center',
  //         fontWeight,
  //         fontSize
  //       }
  //     };
  //   },
  // },
  {
    Header: '',
    headerStyle: {
      'borderRight': '3px solid rgba(0,0,0,0.15)',
      'borderLeft': '3px solid rgba(0,0,0,0.15)'
    },
    columns: [
      {
        Header:() => {
          return (
            <div>
              {'Verdict'}
            </div>
          );
        },
        accessor: 'verdict',
        headerStyle: { 'borderLeft': '3px solid rgba(0,0,0,0.15)' },
        filterAll: true,
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'normal';
          let fontSize = '14px';
          if (rowInfo) {
            if (
              rowInfo.row['verdict'] === 0 ||
              rowInfo.row['verdict'] === undefined ||
              rowInfo.row['verdict'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize,
              borderLeft: '3px solid rgba(0,0,0,0.15)'
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <div>
              {'lastCrawlTime'}
            </div>
          );
        },
        accessor: 'lastCrawlTime',
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'normal';
          let fontSize = '14px';
          if (rowInfo) {
            if (
              rowInfo.row['lastCrawlTime'] === 0 ||
              rowInfo.row['lastCrawlTime'] === undefined ||
              rowInfo.row['lastCrawlTime'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <div>
              {'pageFetchState'}
            </div>
          );
        },
        accessor: 'pageFetchState',
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'normal';
          let fontSize = '14px';
          if (rowInfo) {
            if (
              rowInfo.row['pageFetchState'] === 0 ||
              rowInfo.row['pageFetchState'] === undefined ||
              rowInfo.row['pageFetchState'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <div>
              {'coverageState'}
            </div>
          );
        },
        accessor: 'coverageState',
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'normal';
          let fontSize = '14px';
          if (rowInfo) {
            if (
              rowInfo.row['coverageState'] === 0 ||
              rowInfo.row['coverageState'] === undefined ||
              rowInfo.row['coverageState'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize,
            }
          };
        },
        getHeaderProps: () => {
          return {
            style: {
              marginRight: '10px solid'
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <div>
              {'indexingState'}
            </div>
          );
        },
        accessor: 'indexingState',
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'normal';
          let fontSize = '14px';
          if (rowInfo) {
            if (
              rowInfo.row['indexingState'] === 0 ||
              rowInfo.row['indexingState'] === undefined ||
              rowInfo.row['indexingState'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        },
        getHeaderProps: () => {
          return {
            style: {
              marginRight: '10px solid'
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <div>
              {'robotsTxtState'}
            </div>
          );
        },
        accessor: 'robotsTxtState',
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'normal';
          let fontSize = '14px';
          if (rowInfo) {
            if (
              rowInfo.row['robotsTxtState'] === 0 ||
              rowInfo.row['robotsTxtState'] === undefined ||
              rowInfo.row['robotsTxtState'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <div>
              {'crawledAs'}
            </div>
          );
        },
        accessor: 'crawledAs',
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'normal';
          let fontSize = '14px';
          if (rowInfo) {
            if (
              rowInfo.row['crawledAs'] === 0 ||
              rowInfo.row['crawledAs'] === undefined ||
              rowInfo.row['crawledAs'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      }
    ]
  }
];

class SEOHealthInspectionScreen extends Component {
  constructor() {
    super();
    this.state = {
      loading: true
    };
  }
  componentDidMount() {
    const url = this.props.location.search.split('=')[1];
    this.setState({ url });
    const initialPath = this.props.location.pathname.split('/')[1];
    let platform = initialPath;
    if (initialPath.includes('-')) {
      platform = initialPath.split('-')[1];
    }
    this.props.fetchRawData(platform, url);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        loading: false
      });
    }
  }
  async getCsv() {
    const csv = await this.generateCsv();
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.download = `gsc_inspect_url_${decodeURIComponent(this.state.url)}_results.csv`;
    link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    link.click();
  }
  async generateCsv() {
    let data = this.props.data;
    let csv = '';
    let features = ['pageUrl', 'timestamp','verdict','lastCrawlTime', 'pageFetchState', 'coverageState','indexingState','robotsTxtState', 'crawledAs', 'userCanonical','googleCanonical'];
    for (let feature of features) {
      csv = `${csv}${feature},`;
    }
    csv = `${csv}\r\n`;
    for (let item of data) {
      for (let feature of features) {
        csv = `${csv}${item[feature]},`;
      }
      csv = `${csv}\r\n`;
    }
    return csv;
  }

  render() {
    let data =
      this.props.data && this.props.data.length > 0
        ? this.props.data.asMutable()
        : [];

    return (
      <div style={{ padding: '20px', height: '100%' }}>
        <div style={{ display: 'inline-block', width: '100%' }}>
          <h1 style={{ display: 'inline-block' }}>
          Google Search Console Inspection runs for {decodeURIComponent(this.state.url)}
          </h1>
          <Button
            style={{ float: 'right' }}
            basic
            disabled={this.props.loading}
            loading={this.props.loading}
            icon="download"
            content="Download CSV"
            onClick={this.getCsv.bind(this)}
          />
          <div>
          </div>
        </div>

        <table border="1" style={{ width: "50%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th>Verdict</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td>{row.enum}</td>
                <td>{row.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br></br>
        <ReactTable
          loading={this.props.loading}
          data={data}
          columns={columns}
          pageSize={data.length}
          showPagination={false}
          showPageSizeOptions={false}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRawData(platform, domain) {
      dispatch(Actions.fetchSEOHealthInspectionDataForPlatform(platform, domain));
    }
  };
}

function mapStateToProps(state) {
  return {
    data: state.SeoHealthForPlatform.rawInspectionDataForDomain.data,
    loading: state.SeoHealthForPlatform.rawInspectionDataForDomain.loading
  };
}
SEOHealthInspectionScreen.propTypes = {
  data: PropTypes.array,
  fetchRawData: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  loading: PropTypes.bool
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SEOHealthInspectionScreen);
