const RED = '#FF0000';
const AMBER = '#f2711c';
const GREEN = '#21ba45';
const NOT_AVAILABLE_COLOUR = '#D3D3D3';

function getAverageColour(average) {
  let averageColour = NOT_AVAILABLE_COLOUR;
  if (isRed(average)) {
    averageColour = RED;
  }
  if (isAmber(average)) {
    averageColour = AMBER;
  }
  if (isGreen(average)) {
    averageColour = GREEN;
  }
  return averageColour;
}

const isGreen = average => {
  return average <= 100 && average >= 90;
};
  
const isAmber = average => {
  return average >= 50 && average < 90;
};
  
const isRed = average => {
  return average < 50 && average > 0 ;
};

export { isGreen, isRed, isAmber, getAverageColour, RED, AMBER, GREEN };
