/* eslint-disable indent */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../store/actions';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import moment from 'moment';
import { Button, Popup } from 'semantic-ui-react';
import {
  getMainMetricsColor,
  getLcpColor,
  getClsColor,
  getTbtColor,
  getTtiColor,
  getFcpColor,
  getSiColor,
  getPageWeightColor,
  getJSETColor,
  getFmpColor
} from '../helpers/averagePerformance';
import { downloadJsonReport } from '../helpers/downloadJsonReport';

const columns = [
  {
    Header: 'Date',
    accessor: 'Date',
    Cell: row => {
      return moment(row.value).toString();
    }
  },
  {
    Header: 'Report',
    accessor: 'Report',
    Cell: row => (
      row.value ? (
        <button
          onClick={() => downloadJsonReport(row.value, row.original.Date, row.original.PageUrl)}
          style={{ cursor: 'pointer', color: '#652c90', textDecoration: 'none', background: 'none', border: 'none', fontFamily: 'Lato, Helvetica Neue, Arial, Helvetica, sans-serif' }}
        >
          Download Report
        </button>
      ) : (
        <span style={{ color: 'rgb(211, 211, 211)', fontSize: '12px'}}> 
        N/A
        </span>
      )
    ),
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'center', fontSize: '14px', fontWeight: 'normal',}
  },
  {
    Header: '',
    headerStyle: {
      'borderRight': '3px solid rgba(0,0,0,0.15)',
      'borderLeft': '3px solid rgba(0,0,0,0.15)'
    },
    columns: [
      {
        Header:() => {
          return (
            <Popup
              content={
                <div className="ui card" style={{width: 'fit-content'}}>
                  <div className="content">
                    <div className="center aligned description">
                      <div style={{ display: 'inline-grid' }}>
                        <div style={{ display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#FF0000' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"< 50"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#f2711c' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{">= 50 && < 90"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#21ba45' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"> 90"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              trigger={
                <div>
                  {'Performance'}
                </div>
              }
              position="top center"
            />
          );
        },
        accessor: 'Performance',
        headerStyle: { 'border-left': '3px solid rgba(0,0,0,0.15)' },
        filterAll: true,
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'bolder';
          let fontSize = '22px';
          if (rowInfo) {
            colour = getMainMetricsColor(rowInfo.row['Performance']);
            if (
              rowInfo.row['Performance'] === 0 ||
              rowInfo.row['Performance'] === undefined ||
              rowInfo.row['Performance'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize,
              borderLeft: '3px solid rgba(0,0,0,0.15)'
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <Popup
              content={
                <div className="ui card" style={{width: 'fit-content'}}>
                  <div className="content">
                    <div className="center aligned description">
                      <div style={{ display: 'inline-grid' }}>
                        <div style={{ display: 'inline-flex',padding: '10px' }}>
                          <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#FF0000' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"< 50"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#f2711c' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{">= 50 && < 90"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#21ba45' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"> 90"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              trigger={
                <div>
                  {'Accessibility'}
                </div>
              }
              position="top center"
            />
          );
        },
        accessor: 'Accessibility',
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'bolder';
          let fontSize = '22px';
          if (rowInfo) {
            colour = getMainMetricsColor(rowInfo.row['Accessibility']);
            if (
              rowInfo.row['Accessibility'] === 0 ||
              rowInfo.row['Accessibility'] === undefined ||
              rowInfo.row['Accessibility'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <Popup
              content={
                <div className="ui card" style={{width: 'fit-content'}}>
                  <div className="content">
                    <div className="center aligned description">
                      <div style={{ display: 'inline-grid' }}>
                        <div style={{ display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#FF0000' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"< 50"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#f2711c' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{">= 50 && < 90"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#21ba45' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"> 90"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              trigger={
                <div>
                  {'SEO'}
                </div>
              }
              position="top center"
            />
          );
        },
        accessor: 'SEO',
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'bolder';
          let fontSize = '22px';
          if (rowInfo) {
            colour = getMainMetricsColor(rowInfo.row['SEO']);
            if (
              rowInfo.row['SEO'] === 0 ||
              rowInfo.row['SEO'] === undefined ||
              rowInfo.row['SEO'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <Popup
              content={
                <div className="ui card" style={{width: 'fit-content'}}>
                  <div className="content">
                    <div className="center aligned description">
                      <div style={{ display: 'inline-grid' }}>
                        <div style={{ display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#FF0000' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"< 50"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#f2711c' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{">= 50 && < 90"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#21ba45' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"> 90"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              trigger={
                <div>
                  {'Best Practices'}
                </div>
              }
              position="top center"
            />
          );
        },
        accessor: 'BestPractices',
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'bolder';
          let fontSize = '22px';
          if (rowInfo) {
            colour = getMainMetricsColor(rowInfo.row['BestPractices']);
            if (
              rowInfo.row['BestPractices'] === 0 ||
              rowInfo.row['BestPractices'] === undefined ||
              rowInfo.row['BestPractices'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize,
            }
          };
        },
        getHeaderProps: () => {
          return {
            style: {
              marginRight: '10px solid'
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <Popup
              content={
                <div className="ui card" style={{width: 'fit-content'}}>
                  <div className="content">
                    <div className="center aligned description">
                      <div style={{ display: 'inline-grid' }}>
                        <div style={{ display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#FF0000' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"> 2.5"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#f2711c' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"> 1.5 && <=2.5"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#21ba45' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"> 0 && <= 1.5"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              trigger={
                <div>
                  {'Page Weight (MB)'}
                </div>
              }
              position="top center"
            />
          );
        },
        accessor: 'PageWeight',
        headerStyle: { 'borderRight': '3px solid rgba(0,0,0,0.15)' },
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'bolder';
          let fontSize = '22px';
          if (rowInfo) {
            colour = getPageWeightColor(rowInfo.row['PageWeight']);
            if (
              rowInfo.row['PageWeight'] === 0 ||
              rowInfo.row['PageWeight'] === undefined ||
              rowInfo.row['PageWeight'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize,
              borderRight: '3px solid rgba(0,0,0,0.15)'
            }
          };
        },
        getHeaderProps: () => {
          return {
            style: {
              marginRight: '10px solid'
            }
          };
        }
      }
    ]
  },

  {
    Header: 'Performance Metrics',
    columns: [
      {
        Header:() => {
          return (
            <Popup
              content={
                <div className="ui card" style={{width: 'fit-content'}}>
                  <div className="content">
                    <div className="center aligned description">
                      <div style={{ display: 'inline-grid' }}>
                        <div style={{ display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#FF0000' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"> 4"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#f2711c' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"> 2 && <=4"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#21ba45' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"<= 2"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              trigger={
                <div>
                  {'FCP (Secs)'}
                </div>
              }
              position="top center"
            />
          );
        },
        accessor: 'Fcp',
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'bolder';
          let fontSize = '22px';
          if (rowInfo) {
            colour = getFcpColor(rowInfo.row['Fcp']);
            if (
              rowInfo.row['Fcp'] === 0 ||
              rowInfo.row['Fcp'] === undefined ||
              rowInfo.row['Fcp'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <Popup
              content={
                <div className="ui card" style={{width: 'fit-content'}}>
                  <div className="content">
                    <div className="center aligned description">
                      <div style={{ display: 'inline-grid' }}>
                        <div style={{ display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#FF0000' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"> 4"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#f2711c' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"> 2 && <=4"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#21ba45' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"<= 2"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              trigger={
                <div>
                  {'Largest Contentful Paint (Secs)'}
                </div>
              }
              position="top center"
            />
          );
        },
        accessor: 'Lcp',
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'bolder';
          let fontSize = '22px';
          if (rowInfo) {
            colour = getLcpColor(rowInfo.row['Lcp']);
            if (
              rowInfo.row['Lcp'] === 0 ||
              rowInfo.row['Lcp'] === undefined ||
              rowInfo.row['Lcp'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <Popup
              content={
                <div className="ui card" style={{width: 'fit-content'}}>
                  <div className="content">
                    <div className="center aligned description">
                      <div style={{ display: 'inline-grid' }}>
                        <div style={{ display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#FF0000' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"> 0.25"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#f2711c' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"> 0.1 && <=0.25"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#21ba45' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"<= 0.1"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              trigger={
                <div>
                  {'Cumulative Layout Shift'}
                </div>
              }
              position="top center"
            />
          );
        },
        accessor: 'Cls',
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'bolder';
          let fontSize = '22px';
          if (rowInfo) {
            colour = getClsColor(rowInfo.row['Cls']);
            if (
              rowInfo.row['Cls'] === 0 ||
              rowInfo.row['Cls'] === undefined ||
              rowInfo.row['Cls'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <Popup
              content={
                <div className="ui card" style={{width: 'fit-content'}}>
                  <div className="content">
                    <div className="center aligned description">
                      <div style={{ display: 'inline-grid' }}>
                        <div style={{ display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#FF0000' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"> 5.8"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#f2711c' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"> 4.3 && <=5.8"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#21ba45' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"<= 4.3"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              trigger={
                <div>
                  {'Speed Index (Secs)'}
                </div>
              }
              position="top center"
            />
          );
        },
        accessor: 'SpeedIndex',
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'bolder';
          let fontSize = '22px';
          if (rowInfo) {
            colour = getSiColor(rowInfo.row['SpeedIndex']);
            if (
              rowInfo.row['SpeedIndex'] === 0 ||
              rowInfo.row['SpeedIndex'] === undefined ||
              rowInfo.row['SpeedIndex'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <Popup
              content={
                <div className="ui card" style={{width: 'fit-content'}}>
                  <div className="content">
                    <div className="center aligned description">
                      <div style={{ display: 'inline-grid' }}>
                        <div style={{ display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#FF0000' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"> 7.3"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#f2711c' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"> 3.8 && <=7.3"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#21ba45' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"<= 3.8"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              trigger={
                <div>
                  {'Time To Interactive (Secs)'}
                </div>
              }
              position="top center"
            />
          );
        },
        accessor: 'TimeToInteractive',
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'bolder';
          let fontSize = '22px';
          if (rowInfo) {
            colour = getTtiColor(rowInfo.row['TimeToInteractive']);
            if (
              rowInfo.row['TimeToInteractive'] === 0 ||
              rowInfo.row['TimeToInteractive'] === undefined ||
              rowInfo.row['TimeToInteractive'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <Popup
              content={
                <div className="ui card" style={{width: 'fit-content'}}>
                  <div className="content">
                    <div className="center aligned description">
                      <div style={{ display: 'inline-grid' }}>
                        <div style={{ display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#FF0000' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"> 0.6"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#f2711c' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"> 0.3 && <=0.6"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#21ba45' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"<= 0.3"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              trigger={
                <div>
                  {'Total Blocking Time (Secs)'}
                </div>
              }
              position="top center"
            />
          );
        },
        accessor: 'TotalBlockingTime',
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'bolder';
          let fontSize = '22px';
          if (rowInfo) {
            colour = getTbtColor(rowInfo.row['TotalBlockingTime']);
            if (
              rowInfo.row['TotalBlockingTime'] === 0 ||
              rowInfo.row['TotalBlockingTime'] === undefined ||
              rowInfo.row['TotalBlockingTime'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <Popup
              content={
                <div className="ui card" style={{width: 'fit-content'}}>
                  <div className="content">
                    <div className="center aligned description">
                      <div style={{ display: 'inline-grid' }}>
                        <div style={{ display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#FF0000' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{">= 10"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#f2711c' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{">= 5 && < 10"}</p>
                        </div>
                        <div style={{display: 'inline-flex',padding: '10px' }}>
                        <button
                          className="square ui icon button"
                          style={{ backgroundColor: '#21ba45' }}
                        ></button>
                        <p style={{ margin: '5px' }}>{"< 5"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              trigger={
                <div>
                  {'Observed Load (Secs)'}
                </div>
              }
              position="top center"
            />
          );
        },
        accessor: 'ObservedLoad',
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'bolder';
          let fontSize = '22px';
          if (rowInfo) {
            colour = getFmpColor(rowInfo.row['ObservedLoad']);
            if (
              rowInfo.row['ObservedLoad'] === 0 ||
              rowInfo.row['ObservedLoad'] === undefined ||
              rowInfo.row['ObservedLoad'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      },
      {
        Header:() => {
          return (
            <Popup
              content={
                <div className="ui card" style={{width: 'fit-content'}}>
                <div className="content">
                  <div className="center aligned description">
                    <div style={{ display: 'inline-grid' }}>
                      <div style={{ display: 'inline-flex',padding: '10px' }}>
                      <button
                        className="square ui icon button"
                        style={{ backgroundColor: '#FF0000' }}
                      ></button>
                      <p style={{ margin: '5px' }}>{"> 3.5"}</p>
                      </div>
                      <div style={{display: 'inline-flex',padding: '10px' }}>
                      <button
                        className="square ui icon button"
                        style={{ backgroundColor: '#f2711c' }}
                      ></button>
                      <p style={{ margin: '5px' }}>{"> 2.0 && <=3.5"}</p>
                      </div>
                      <div style={{display: 'inline-flex',padding: '10px' }}>
                      <button
                        className="square ui icon button"
                        style={{ backgroundColor: '#21ba45' }}
                      ></button>
                      <p style={{ margin: '5px' }}>{"> 0 && <= 2.0"}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
              trigger={
                <div>
                  {'JavaScript Execution Time (Secs)'}
                </div>
              }
              position="top center"
            />
          );
        },
        accessor: 'JavaScriptExecutionTime',
        Cell: row => {
          return row.value === 0 || row.value === undefined ? 'N/A' : row.value;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'bolder';
          let fontSize = '22px';
          if (rowInfo) {
            colour = getJSETColor(rowInfo.row['JavaScriptExecutionTime']);
            if (
              rowInfo.row['JavaScriptExecutionTime'] === 0 ||
              rowInfo.row['JavaScriptExecutionTime'] === undefined ||
              rowInfo.row['JavaScriptExecutionTime'] === 'N/A'
            ) {
              // eslint-disable-next-line
              (fontWeight = 'normal'), (fontSize = '12px');
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        }
      }
    ]
  }
];

class LighthouseTestRuns3g extends Component {
  constructor() {
    super();
    this.state = {
      loading: true
    };
  }
  componentDidMount() {
    const url = this.props.location.search.split('=')[1];
    this.setState({ url });
    const initialPath = this.props.location.pathname.split('/')[1];
    let platform = initialPath;
    if (initialPath.includes('-')) {
      platform = initialPath.split('-')[1];
    }
    this.props.fetchRawData(platform, url);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        loading: false
      });
    }
  }
  async getCsv() {
    const csv = await this.generateCsv();
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.download = `test_runs.csv`;
    link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    link.click();
  }
  async generateCsv() {
    let data = this.props.data;
    let csv = '';
    let features = ['Date','Brand','Locale','PageUrl','Performance', 'Accessibility', 'SEO','BestPractices','PageWeight', 'SpeedIndex', 'Cls','ObservedLoad','TotalBlockingTime','TimeToInteractive','Fcp','Lcp', 'JavaScriptExecutionTime'];
    for (let feature of features) {
      csv = `${csv}${feature},`;
    }
    csv = `${csv}\r\n`;
    for (let item of data) {
      for (let feature of features) {
        csv = `${csv}${item[feature]},`;
      }
      csv = `${csv}\r\n`;
    }
    return csv;
  }
  render() {
    let data =
      this.props.data && this.props.data.length > 0
        ? this.props.data.asMutable()
        : [];
    return (
      <div style={{ padding: '20px', height: '100%' }}>
        <div style={{ display: 'inline-block', width: '100%' }}>
          <h1 style={{ display: 'inline-block' }}>
            Lighthouse test runs for {decodeURIComponent(this.state.url)}
          </h1>
          <Button
            style={{ float: 'right' }}
            basic
            disabled={this.props.loading}
            loading={this.props.loading}
            icon="download"
            content="Download CSV"
            onClick={this.getCsv.bind(this)}
          />
          <div>
          </div>
        </div>
        <ReactTable
          loading={this.props.loading}
          data={data}
          columns={columns}
          pageSize={data.length}
          showPagination={false}
          showPageSizeOptions={false}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRawData(platform, url) {
      dispatch(Actions.fetchLighthouseRawDataForUrlAndPlatform(platform, url, '3g'));
    }
  };
}

function mapStateToProps(state) {
  return {
    data: state.lighthouseForPlatform.rawDataForUrl.data,
    loading: state.lighthouseForPlatform.rawDataForUrl.loading
  };
}
LighthouseTestRuns3g.propTypes = {
  data: PropTypes.array,
  fetchRawData: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  loading: PropTypes.bool
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LighthouseTestRuns3g);
